import ApiService from "@/common/api/base.service";

export default {
  getUser(userId) {
    return ApiService.get("users", userId);
  },
  registerUser(name, email, password, role) {
    return ApiService.post("/users", { name, email, password, role });
  },
  deleteUser(id) {
    return ApiService.delete(`/users/${id}`);
  },
  updateUser(slug, params) {
    return ApiService.update("users", slug, { user: params });
  },
  getUsers(pageNumber, searchStr) {
    return ApiService.get(
      "/users",
      `?page=${pageNumber}&searchStr=${searchStr}`
    );
  },
  getCurrentPlans(id){
    return ApiService.get(`/user/${id}/current-plan`)
  },
  getAllPlans(id){
    return ApiService.get(`/user/${id}/plans`)
  },
  submitShopifyNewPlan(storeId, planId, optionIds, isOnboarding) {
    return ApiService.post(`/shopify/store/${storeId}/generate-charge`, {
      plan_id: planId,
      option_ids: optionIds,
      is_onboarding: isOnboarding,
    })
  },
  savePlan(params){
    return ApiService.post(`/shopify/user/${params.user_id}/activate-charge`, params)
  }

};