import { updateField } from "vuex-map-fields";
import deepmerge from "deepmerge";

import { cancelPayout } from "./actions/cancelPayout";
import { confirmPayout } from "./actions/confirmPayout";
import { createComment } from "./actions/createComment";
import { createPayout } from "./actions/createPayout";
import { deletePayout } from "./actions/deletePayout";
import { fetchDashboardStats } from "./actions/fetchDashboardStats";
import { fetchPaidRecords } from "./actions/fetchPaidRecords";
import { fetchPayableOrders } from "./actions/fetchPayableOrders";
import { fetchPayout } from "./actions/fetchPayout";
import { fetchPayoutEvents } from "./actions/fetchPayoutEvents";
import { fetchPayoutPreview } from "./actions/fetchPayoutPreview";
import { fetchPayouts } from "./actions/fetchPayouts";
import { fetchRecords } from "./actions/fetchRecords";
import { fetchSourceCompletePayouts } from "./actions/fetchSourceCompletePayouts";
import { fetchSourcePayouts } from "./actions/fetchSourcePayouts";
import { updatePayout } from "./actions/updatePayout";

import { setDashboardStats } from "./mutations/setDashboardStats";
import { setPaidRecords } from "./mutations/setPaidRecords";
import { setPaidRecordsPagination } from "./mutations/setPaidRecordsPagination";
import { setPayableOrders } from "./mutations/setPayableOrders";
import { setPayoutDetails } from "./mutations/setPayoutDetails";
import { setPayouts } from "./mutations/setPayouts";
import { setPayoutsPagination } from "./mutations/setPayoutsPagination";
import { setPayoutsPreview } from "./mutations/setPayoutsPreview";
import { setRecords } from "./mutations/setRecords";
import { setRecordsPagination } from "./mutations/setRecordsPagination";
import { setSourceCompletePayouts } from "./mutations/setSourceCompletePayouts";
import { setSourcePayouts } from "./mutations/setSourcePayouts";

const state = {
  currency: "USD",
  comment: null,
  dashboardStats: {},
  filters: [],
  isAddOrdersToInvoiceVisible: false,
  isOrderDetailsVisible: false,
  isPayoutCreated: false,
  isPayoutDialogVisible: false,
  isStatusUpdating: false,
  isViewingPayableOrders: true,
  isViewPayoutDialogVisible: false,
  isViewPayoutRecordLoading: false,
  limiter: 10,
  loadindDashboardStats: false,
  loadingPayableOrders: true,
  loadingPayoutRecords: true,
  loadingPayouts: false,
  payableOrderCurrentPage: 1,
  payableOrders: {},
  payableOrdersFilters: { target_store: "All Stores", orders_status: "unpaid", date_range: "Last 30 days" },
  payoutDetails: {},
  payouts: {},
  payoutsCurrentPage: 1,
  payoutsDashboard: { commission: 0, payoutIds: [], taxes: 0, total: 0 },
  payoutsFilters: { target_store: "All Stores", orders_status: "unpaid", date_range: "Last 30 days" },
  payoutsPagination: {},
  payoutsPreview: null,
  payoutStatusOptions: ['paid', 'unpaid'],
  preview: { orders_count: 0, status: ["unpaid"] },
  records: [],
  recordsCurrentPage: 1,
  recordsPagination: {},
  searchTerm: "",
  selectedPayoutOrders: [],
  selectedRecord: "",
  selectedRecordId: "",
  selectedRecordStore: { text: "All Stores" },
  selectedStore: { text: "All Stores" },
  sourcePayoutsCurrentPage: 1,
  timeZone: "Australia/Melbourne",

  /* Source Store */
  sourcePayouts: {
    pagination: {},
    payouts: [],
  },
  sourcePayoutsFilters: { target_store: "All Stores", orders_status: "not_confirmed", date_range: "Last 30 days" },
  totalSourcePayouts: 0,
  sourceCompletePayouts: {
    pagination: {},
    payouts: []
  },
  sourceCompletePayoutsFilters: { target_store: "All Stores", orders_status: "payment_confirmed", date_range: "Last 30 days" },
  sourceCompletePayoutsCurrentPage: 1,
  bulkSourcePayouts: [],

  /* Paid Payouts */
  bulkPaidPayouts: [],
  bulkUnpaidPayouts: [],
  paidPayoutsFilters: { target_store: "All Stores", orders_status: "paid_received", date_range: "Last 30 days" },
  paidRecords: [],
  paidRecordsCurrentPage: 1,
  paidRecordsPagination: {},
  selectedPaidRecordStore: { text: "All Stores" },
  lineItems: [],
  payoutTotal: null,
};

const actions = deepmerge.all([
  cancelPayout,
  confirmPayout,
  createComment,
  createPayout,
  deletePayout,
  fetchDashboardStats,
  fetchPaidRecords,
  fetchPayableOrders,
  fetchPayout,
  fetchPayoutEvents,
  fetchPayoutPreview,
  fetchPayouts,
  fetchRecords,
  fetchSourceCompletePayouts,
  fetchSourcePayouts,
  updatePayout,
]);

const mutations = deepmerge.all([
  setDashboardStats,
  setPaidRecords,
  setPaidRecordsPagination,
  setPayableOrders,
  setPayoutDetails,
  setPayouts,
  setPayoutsPagination,
  setPayoutsPreview,
  setRecords,
  setRecordsPagination,
  setSourceCompletePayouts,
  setSourcePayouts,
]);

mutations['updateField'] = updateField;

import { getters } from "./getters";

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
};
