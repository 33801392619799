import axios from "axios";

export const confirmPayout = {
  async confirmPayout({ commit }, payload)  {
    try {
      const response = await axios.post('stores/payout/confirm-payment', {
        ...payload
      });
    } catch (error) {
      console.log(error);
    }
  }
};
