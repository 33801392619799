import axios from "axios";

export const fetchOrderMessages = {
  async fetchOrderMessages({ commit }, { currentStoreId, store_id, order_id }) {
    try {
      const response = await axios.get(`/stores/${currentStoreId}/get-order-messages/`, {
        params: {
          store_id,
          order_id
        }
      });
      return response.data.messages;

    } catch (error) {
      console.log(error);
    }
  }
}
