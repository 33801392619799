import { getters } from './getters'
import { updateField } from 'vuex-map-fields'
import deepmerge from 'deepmerge'

import { dismissIssue } from './actions/dismissActivity'
import { fetchActivities } from './actions/fetchActivities'
import { setActivities } from './mutations/setActivities'

const state = {
  loading: false,
  generalUpdatesEventOptions: [
    { key: 'event', value: 'all_events', label: 'All Events (Default)' },
    { key: 'event', value: 'orders', label: 'Orders' },
    { key: 'event', value: 'inventory_location', label: 'Inventory Location' },
    { key: 'event', value: 'store_connections', label: 'Store Connections' },
    { key: 'event', value: 'payouts', label: 'Payouts' }
  ],
  generalUpdates: null,
  generalUpdatesCurrentPage: '',
  generalUpdatesEvents: 'All Events',
  generalUpdatesFilters: { store_domain: 'all stores', event: 'all_events' },
  generalUpdatesPagination: null,
  generalUpdatesPartnerStore: null,
  generalUpdatesSearchStr: '',

  orderIssuesEventOptions: [
    { key: 'event', value: 'all_events', label: 'All Events (Default)' },
    { key: 'event', value: 'push_issue', label: 'Push Issue' },
    { key: 'event', value: 'tracking_number', label: 'Tracking Number' },
    { key: 'event', value: 'order_edit', label: 'Order Edit' }
  ],
  orderIssues: null,
  orderIssuesCurrentPage: '',
  orderIssuesEvents: 'All Events',
  orderIssuesFilters: { store_domain: 'all stores', event: 'all_events' },
  orderIssuesPagination: null,
  orderIssuesPartnerStore: null,
  orderIssuesSearchStr: '',

  productIssuesEventOptions: [
    { key: 'event', value: 'all_events', label: 'All Events (Default)' },
    { key: 'event', value: 'sku', label: 'SKU' },
    { key: 'event', value: 'mapping', label: 'Mapping' },
    { key: 'event', value: 'update', label: 'Update' },
    { key: 'event', value: 'product_import', label: 'Product Import' }
  ],
  productIssues: null,
  productIssuesCurrentPage: '',
  productIssuesEvents: 'All Events',
  productIssuesFilters: { store_domain: 'all stores', event: 'all_events' },
  productIssuesPagination: null,
  productIssuesPartnerStore: null,
  productIssuesSearchStr: '',

  selectedTab: 'product',
  tabs: [
    { value: 'product', label: 'Product Issues' },
    { value: 'order', label: 'Order Issues' },
    { value: 'general', label: 'General Updates' }
  ],
}

const actions = deepmerge.all([
  dismissIssue,
  fetchActivities,
])

const mutations = deepmerge.all([
  setActivities,
  {}
])

mutations['updateField'] = updateField

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
