import {
    SET_CURRENT_CONFIGS,
} from "@/store/mutations.type";
import {
    RESET_CURRENT_CONFIGS, ADD_NOTIFICATION, FORMAT_AND_SET_CURRENT_CONFIGS,
    LOAD_CURRENT_CONFIGURATIONS, UPDATE_CURRENT_CONFIGURATIONS
} from "@/store/actions.type";

import ConfigsApiService from "@/common/api/configs.service";

const mutations = {
    [SET_CURRENT_CONFIGS](state, configs) {
        state.currentConfigs = configs;
    }
};

const actions = {
    [RESET_CURRENT_CONFIGS]({ commit }) {
        commit(SET_CURRENT_CONFIGS, null);
    },
    [LOAD_CURRENT_CONFIGURATIONS]({ dispatch }, { currentUserId }) {
        return new Promise((resolve) => {
            ConfigsApiService.loadConfigurations(currentUserId)
                .then(({ data }) => { 
                    if (data.success) {
                        // convert the data format
                        dispatch(FORMAT_AND_SET_CURRENT_CONFIGS, {
                            configsData: data.configurations
                        });
                    } else {
                        dispatch(`notification/${ADD_NOTIFICATION}`, {
                            notification: {
                                id: 'N' + (Math.floor(Math.random() * 100000000)),
                                position: "top-left",
                                type: "error",
                                body: "Failed to load product sync configurations!",
                                state: 0,
                                length: 6000, // seconds
                                data: null,
                            },
                        }, {root:true});
                    }
                    resolve(data);
                });
        });
        
    },
    [UPDATE_CURRENT_CONFIGURATIONS]({ dispatch }, { userId, newConfigSettings }) {
        let paramData = [];
        newConfigSettings.forEach((group) => {
            group.configs.forEach((config) => {
                paramData.push({
                    key: config.key,
                    is_active: config.value,
                });
            });
        });
        return new Promise((resolve) => {
            ConfigsApiService.saveConfigurations(userId, paramData)
                .then(({ data }) => {
                    if (data.success) {
                        // convert the data format
                        dispatch(FORMAT_AND_SET_CURRENT_CONFIGS, {
                            configsData: data.configurations
                        });
                    } else {
                        dispatch(`notification/${ADD_NOTIFICATION}`, {
                            notification: {
                                id: 'N' + (Math.floor(Math.random() * 100000000)),
                                position: "top-left",
                                type: "error",
                                body: "Failed to save product sync configurations!",
                                state: 0,
                                length: 6000, // seconds
                                data: null,
                            },
                        }, {root:true});
                    }
                }).finally(() => {
                    resolve();
                });
        });
    },
    [FORMAT_AND_SET_CURRENT_CONFIGS](context, { configsData }) {
        let currentConfigsSet = {
            product: [],
            variant: [],
            general: [],
        };
        configsData.forEach((config) => {
            if (config.is_active === "1" || config.is_active === 1 || config.is_active === true) {
                config.value = true;
            } else {
                config.value = false;
            }
            if (config.group === "product") {
                currentConfigsSet.product.push(config);
            } else if (config.group === "variant") {
                currentConfigsSet.variant.push(config);
            } else if (config.group === "general") {
                currentConfigsSet.general.push(config);
            }
        });
        context.commit(SET_CURRENT_CONFIGS, currentConfigsSet);
    },
};

export default {
    namespaced: true,
    state: {
        currentConfigs: null,
    },
    mutations,
    actions,
}