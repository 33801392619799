import axios from "axios";

export const fetchPayout = {
  async fetchPayout({ commit }, payload) {
    try {
      const response = await axios.post("stores/payout/view-payout", {
        ...payload
      });
      const flatResponse = response.data.data.flat();
      commit("SET_PAYOUT_DETAILS", response.data.payout);
      commit("SET_PAYOUTS_PREVIEW", flatResponse);
    } catch (error) {
      console.log(error);
    }
  }
};
