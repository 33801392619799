<script>
import Logo from '@/icons/Logo.vue'
export default {
  name: 'onboardingHeader',

  components: {
    Logo
  }
}
</script>

<template>
  <header class="login-header p-t-1">
    <Logo />
  </header>
</template>
