import axios from "axios";

export const updateProfile = {
  async updateProfile({ commit }, { payload, storeId }) {
    const { data: { success, profile } } = await axios.post(`stores/${storeId}/coco-profile`, {
      ...payload
    })
    if(success) {
      commit('SET_PROFILE', profile)
    }
  }
}
