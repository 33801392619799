import ActivityLogApiService from "@/common/api/activityLog.service";
import {
    FETCH_ACTIVITYLOG_LIST, LOAD_AVAILABLE_TYPES, FETCH_ACTIVITY_LOGS_BY_URL
} from "@/store/actions.type";
import { SET_IS_LOADING, SET_ACTIVITY_LOGS_PAGINATE, SET_ACTIVITY_LOGS_PAGINATE_CURRENT_PAGE } from "@/store/mutations.type";

const state = {
    isLoading: false,
    activityLogsList: null,
    currentPage: 1,
    lastPage: 1,
    availableTypes: [],
    activityLogsPaginate: {
        current_page: 0,
        last_page: 0,
        search_path: null,
        limiter: 0,
        total: 0,
    }
};

const mutations = {
    [SET_IS_LOADING](state, value) {
        state.isLoading = value;
    },
    [SET_ACTIVITY_LOGS_PAGINATE](state, value) {
        state.activityLogsPaginate.current_page = value.current_page;
        state.activityLogsPaginate.last_page = value.last_page;
        state.activityLogsPaginate.search_path = value.search_path;
        state.activityLogsPaginate.limiter = value.limiter;
        state.activityLogsPaginate.total = value.total;
    },
    [SET_ACTIVITY_LOGS_PAGINATE_CURRENT_PAGE](state, value) {
        state.productPaginate.current_page = value;
    }
};

const actions = {
    init(context) {
        context.dispatch(LOAD_AVAILABLE_TYPES);
        context.dispatch(FETCH_ACTIVITYLOG_LIST, {
            filters: null,
            limiter: 25
        });
    },
    [FETCH_ACTIVITYLOG_LIST]({ state, commit }, { filters, limiter}) {
        state.isLoading = true;
        return new Promise((resolve) => {
            ActivityLogApiService.fetchActivityLogList(filters, limiter)
                .then(({data}) => {
                    if (data.success) {
                        state.activityLogsList = data.activity_logs;
                        commit(SET_ACTIVITY_LOGS_PAGINATE, {
                            current_page: data.current_page,
                            last_page: data.last_page,
                            search_path: data.path,
                            limiter: data.limiter,
                            total: data.total,
                        });
                    }
                })
                .finally(() => {
                    resolve();
                    state.isLoading = false;
                });
        });
    },
    [FETCH_ACTIVITY_LOGS_BY_URL] ({state, commit}, url) {
        state.isLoading = true;
        return new Promise((resolve) => {
            ActivityLogApiService.fetchActivityLogsByUrl(url)
                .then(({data}) => {
                    if (data.success) {
                        state.activityLogsList = data.activity_logs;
                        commit(SET_ACTIVITY_LOGS_PAGINATE, {
                            current_page: data.current_page,
                            last_page: data.last_page,
                            search_path: data.path,
                            limiter: data.limiter,
                            total: data.total,
                        });
                    }
                })
                .finally(() => {
                    resolve();
                    state.isLoading = false;
                });
        });
    },
    [LOAD_AVAILABLE_TYPES]({ state }) {
        return new Promise((resolve) => {
            ActivityLogApiService.loadAvailableTypes()
                .then(({data}) => {
                    if (data.success) {
                        state.availableTypes = data.types;
                    }
                })
                .finally(() => {
                    resolve();
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}