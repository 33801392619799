import ShopApiService from "@/common/api/shop.service";
import {
  SET_CURRENT_SHOP,
  SET_NEW_SHOP_TYPE,
  SET_SHOP_TYPE,
  SET_IS_CONNECTING_SHOP,
  SET_CONNECTED_SHOPS,
  SET_NEWLY_CONNECTED_SHOP,
  SET_IS_LOADING_CONNECTED_SHOPS,
  SET_NEW_SHOP,
  SET_CONNECTED_SHOP_PAGINATE,
} from "@/store/mutations.type";

import {
  ASSIGN_SHOP_TYPE,
  REGISTER_SHOP,
  REGISTER_SHOP_TYPE,
  ADD_NOTIFICATION,
  LOAD_CONNECTED_SHOPS,
  LOAD_CURRENT_STORE_INVENTORIES,
  LOAD_CONNECTED_SHOPS_BY_URL,
} from "@/store/actions.type";

import { getField, updateField } from "vuex-map-fields";

const mutations = {
  [SET_CURRENT_SHOP](state, shop) {
    state.currentShop = shop;
  },
  SET_IS_LOADING(state, value) {
    state.isLoading = value;
  },
  [SET_IS_CONNECTING_SHOP](state, value) {
    state.isConnectingShop = value;
  },
  [SET_CONNECTED_SHOPS](state, shops) {
    state.connectedShops = shops;
  },
  [SET_SHOP_TYPE](state, type) {
    state.currentShop.type = type;
  },
  [SET_NEW_SHOP_TYPE](state, type) {
    state.newShop.type = type;
  },
  [SET_NEWLY_CONNECTED_SHOP](state, shop) {
    state.newlyConnectedShop = shop;
  },
  [SET_IS_LOADING_CONNECTED_SHOPS](state, value) {
    state.isLoadingConnectedShops = value;
  },
  [SET_NEW_SHOP](state, shop) {
    state.newShop = shop;
  },
  [SET_CONNECTED_SHOP_PAGINATE](state, value) {
    state.connectedShopPaginate.current_page = value.current_page;
    state.connectedShopPaginate.last_page = value.last_page;
    state.connectedShopPaginate.search_path = value.search_path;
    state.connectedShopPaginate.limiter = value.limiter;
    state.connectedShopPaginate.total = value.total;
  },
};

mutations["updateField"] = updateField;

const actions = {
  init(context) {
    return new Promise((resolve) => {
      if (context.state.currentShop === null) {
        context
          .dispatch("loadCurrentShop")
          .then(() => {
            context
              .dispatch(LOAD_CONNECTED_SHOPS, { searchStr: null })
              .finally(() => {
                context.dispatch(LOAD_CURRENT_STORE_INVENTORIES);
                resolve();
              });
          })
          .catch((error) => {
            console.log(error);
            resolve();
          });
      } else {
        context.dispatch(LOAD_CONNECTED_SHOPS, { searchStr: null });
        resolve();
      }
    });
  },

  [ASSIGN_SHOP_TYPE](context, { type }) {
    context.commit(SET_NEW_SHOP_TYPE, type);
  },

  [REGISTER_SHOP_TYPE](context, { store_id, type }) {
    return new Promise((resolve) => {
      ShopApiService.setShopType(store_id, type)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  [REGISTER_SHOP](context, params) {
    context.commit("SET_IS_LOADING", true);
    return new Promise((resolve, reject) => {
      ShopApiService.createStore(params)
        .then(({ data }) => {
          if (data.success) {
            context.commit(SET_CURRENT_SHOP, data.store);
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [LOAD_CONNECTED_SHOPS]({ state, commit }, { searchStr, sortByParam }) {
    if (state.currentShop === null) {
      return;
    }

    let url = encodeURIComponent(searchStr);

    if (sortByParam) {
      const { sort_by, sort_by_desc } = sortByParam;
      url += "&sort_by=" + sort_by + "&sort_by_desc=" + sort_by_desc;
    }

    commit(SET_IS_LOADING_CONNECTED_SHOPS, true);
    return new Promise((resolve) => {
      commit("SET_IS_LOADING", true);
      let shopId = state.currentShop && state.currentShop.id;
      ShopApiService.getShopConnections(shopId, url)
        .then(({ data }) => {
          if (data.success) {
            commit(SET_CONNECTED_SHOPS, data.stores);
          }
        })
        .finally(() => {
          commit("SET_IS_LOADING", false);
          commit(SET_IS_LOADING_CONNECTED_SHOPS, false);
          resolve();
        });
    });
  },

  [LOAD_CONNECTED_SHOPS_BY_URL]({ state, commit }, url) {
    if (state.currentShop === null) {
      return;
    }
    commit(SET_IS_LOADING_CONNECTED_SHOPS, true);
    return new Promise((resolve) => {
      commit("SET_IS_LOADING", true);
      ShopApiService.getShopConnectionsByUrl(url)
        .then(({ data }) => {
          if (data.success) {
            commit(SET_CONNECTED_SHOPS, data.stores);
          }
        })
        .finally(() => {
          commit("SET_IS_LOADING", false);
          commit(SET_IS_LOADING_CONNECTED_SHOPS, false);
          resolve();
        });
    });
  },

  [LOAD_CURRENT_STORE_INVENTORIES]({ state, commit }) {
    if (state.currentShop === null) {
      return;
    }
    commit(SET_IS_LOADING_CONNECTED_SHOPS, true);
    if (state.currentShop && state.currentShop.type != "destination") {
      let destinationStoreIds = [];
      state.connectedShops.forEach((shop) => {
        destinationStoreIds.push(shop.id);
      });
      return new Promise((resolve) => {
        ShopApiService.fetchSourceStoreInventoryAssignmentsForDestinations(
          state.currentShop.id
        )
          .then(({ data }) => {
            state.availableInventories = data.inventories;
            commit(SET_IS_LOADING_CONNECTED_SHOPS, false);
            resolve();
          })
          .catch(() => {
            commit(SET_IS_LOADING_CONNECTED_SHOPS, false);
          });
      });
    } else {
      commit(SET_IS_LOADING_CONNECTED_SHOPS, false);
    }
  },

  loadCurrentShop({ commit }) {
    // TODO: might want to remove the generic isLoading value commit later.
    commit("SET_IS_LOADING", true);
    return new Promise((resolve) => {
      ShopApiService.getAccountStores(1, null)
        .then(({ data }) => {
          if (data.success) {
            // Currently we only allow 1 account per stores
            commit(SET_CURRENT_SHOP, data.stores[0]);
          }
          commit("SET_IS_LOADING", false);
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          commit("SET_IS_LOADING", false);
        });
    });
  },

  loadNewShop(context) {
    context.commit("SET_IS_LOADING", true);
    return new Promise((resolve) => {
      ShopApiService.getAccountStores(1, null)
        .then(({ data }) => {
          if (data.success) {
            // Currently we only allow 1 account per stores
            context.commit(SET_NEW_SHOP, data.stores[0]);
            context.commit("SET_IS_LOADING", false);
            resolve(data);
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("SET_IS_LOADING", false);
        });
    });
  },

  sendEmailInvite(context, { toEmail, shopId }) {
    return new Promise((resolve) => {
      context.commit("SET_IS_LOADING", true);
      ShopApiService.inviteShop(toEmail, shopId)
        .then(({ data }) => {
          if (data.success) {
            //alert('email send');
          } else {
            alert("email not send");
          }
          context.commit("SET_IS_LOADING", false);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          context.commit("SET_IS_LOADING", false);
        });
    });
  },

  connectStoreWithKey(context, { shopId, key }) {
    context.commit("SET_IS_LOADING", true);
    context.commit(SET_IS_CONNECTING_SHOP, true);
    context.commit(SET_NEWLY_CONNECTED_SHOP, null);
    return new Promise((resolve) => {
      ShopApiService.connectShop(shopId, key)
        .then(({ data }) => {
          if (data.success) {
            context.commit(SET_NEWLY_CONNECTED_SHOP, data.target_store);
            context.dispatch(LOAD_CONNECTED_SHOPS, { searchStr: null });
            context.commit(SET_IS_CONNECTING_SHOP, false);
          } else {
            let errorMessage = data?.errors[0];
            context.dispatch(
              `notification/${ADD_NOTIFICATION}`,
              {
                notification: {
                  id: "N" + Math.floor(Math.random() * 100000000),
                  position: "bottom-left",
                  type: "error",
                  body: errorMessage,
                  state: 0,
                  length: 5000,
                  data: null,
                },
              },
              { root: true }
            );
            context.commit(SET_IS_CONNECTING_SHOP, false);
          }
          context.commit("SET_IS_LOADING", false);
          resolve(data);
        })
        .catch((response) => {
          context.commit("SET_IS_LOADING", false);
          /* let data = response.data;
                    let errorMsg = "Failed to connect store with " + key + " Cannot connect to another Source Store. Please try a source store unique key.";
                    if (typeof (data.errors) != 'undefined' && typeof(data.errors.target_store_identifier) != 'undefined') {
                        errorMsg = "We could not connect to this store. Please try another key."
                    }
                    let errorMessage = response.data?.errors[0];
                    context.dispatch(`notification/${ADD_NOTIFICATION}`, {
                        notification: {
                            id: 'N' + (Math.floor(Math.random() * 100000000)),
                            position: "bottom-left",
                            type: errorMessage,
                            body: '',
                            state: 0,
                            length: 5000,
                            data: null,
                        },
                    }, {root:true}); */
          context.commit(SET_IS_CONNECTING_SHOP, false);
        });
    });
  },

  disconnectShop(context, { connectionId, keepProduct }) {
    context.commit("SET_IS_LOADING", true);
    return new Promise((resolve) => {
      ShopApiService.disconnectShop(connectionId, keepProduct)
        .then(({ data }) => {
          if (data.success) {
            context.commit("SET_IS_LOADING", false);
            context.dispatch(LOAD_CONNECTED_SHOPS, { searchStr: null });
          } else {
            alert("shop disconnection failed");
          }
          resolve();
        })
        .catch((error) => {
          console.log(error);
          context.commit("SET_IS_LOADING", false);
        });
    });
  },

  async uninstallShop(context, payload) {
    try {
      context.commit("SET_IS_LOADING", true);
      await ShopApiService.uninstall(payload);
      context.commit("SET_IS_LOADING", false);
    } catch (error) {
      context.commit("SET_IS_LOADING", false);
    }
  },
};

const getters = {
  newShopType(state) {
    return state.newShop.type;
  },
  currentShopType(state) {
    return state.currentShop.type;
  },
  currentShop(state) {
    return state.currentShop;
  },
  newShop(state) {
    return state.newShop;
  },
  isLoading(state) {
    return state.isLoading;
  },
  isLocationPending(state) {
    return state.connectedShops.some((shop) => {
      return shop.status === "pending";
    });
  },
  isPayoutsAvailableForSourceStore({ currentShop, connectedShops }) {
    if (currentShop && currentShop.type === "source") {
      return connectedShops.some((shop) => {
        return shop.has_payout;
      });
    }
  },
  getField,
};

export default {
  namespaced: true,
  state: {
    newShop: {},
    currentShop: null,
    connectedShops: [],
    isLoading: false,
    isConnectingShop: false,
    isLoadingConnectedShops: false,
    newlyConnectedShop: null,
    availableInventories: [],
    connectedShopPaginate: {
      current_page: 0,
      last_page: 0,
      search_path: null,
      limiter: 0,
      total: 0,
    },
  },
  mutations,
  actions,
  getters,
};
