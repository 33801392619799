import OrderApiService from "@/common/api/order.service";
import {
    SET_CURRENT_LOADING,
    SET_CURRENT_ORDER,
    SET_ERROR,
    SET_LOADING,
    SET_ORDERS,
    SET_ORDERS_LIST,
    SET_SHOW_SINGLE_ORDER,
    UNSET_CURRENT_LOADING,
    UNSET_CURRENT_ORDER,
    UNSET_ERROR,
    UNSET_LOADING,
    UNSET_ORDERS_LIST,
    UNSET_SHOW_SINGLE_ORDER,
    SET_PUSH_SETTINGS,
    SET_STORE_ORDER_PUSHING_STATUS,
    SET_CURRENT_PAGE,
    APPEND_ORDERS_LIST,
    UPDATE_ORDER_PUSH_STATUS_ORDER_LIST,
    SET_META_DATA
} from "@/store/mutations.type";
import {
    FETCH_CURRENT_ORDER,
    FETCH_ORDERS,
    HIDE_SINGLE_ORDER,
    PUSH_ORDER_TO_TARGET_STORE,
    AUTO_PUSH_TOGGLE,
    FETCH_PUSH_SETTINGS,
    LAUNCH_STORE_ORDER_PUSHING_STATUS, FETCH_ORDERS_LIST
} from "@/store/actions.type";

const initialState = {
    isLoading: false,
    isLoadingCurrentOrder: false,
    orders: {},
    currentOrder: null,
    showSingleOrder: false,
    errors: [],
    pushSettings: null,
    customShippingTags: null,
    ordersMetaData: {}
};

export const state = {
    ...initialState,
}

const getters = {
    orders(state) {
        return state.orders.orders;
    },
    isLoading(state) {
        return state.isLoading;
    },
    isLoadingCurrentOrder(state) {
        return state.isLoadingCurrentOrder;
    },
    currentOrder(state){
        return state.currentOrder;
    },
    showSingleOrder(state){
        return state.showSingleOrder;
    }
}

const actions = {
    [FETCH_ORDERS](context, {currentShopId, searchStr, sortBy, pageNumber, filters}) {
        context.commit(SET_LOADING);
        return new Promise((resolve) => {
            OrderApiService.getOrders(currentShopId, searchStr, sortBy, pageNumber, filters)
                .then(({ data }) => {
                    context.commit(SET_ORDERS, data.orders);
                    context.commit(SET_META_DATA, data);
                    context.commit(UNSET_LOADING);
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(UNSET_LOADING);
                    resolve(response);
                });
        });
    },
    [FETCH_CURRENT_ORDER](context, {storeId, orderId}) {
        context.commit(SET_SHOW_SINGLE_ORDER);
        context.commit(SET_CURRENT_LOADING);
        context.commit(UNSET_CURRENT_ORDER);
        return new Promise((resolve) => {
            OrderApiService.getCurrentOrder(storeId, orderId)
                .then(({data}) => {
                    context.commit(SET_CURRENT_ORDER, data.order);
                    context.commit(UNSET_CURRENT_LOADING);
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response);
                    context.commit(UNSET_SHOW_SINGLE_ORDER);
                });
        });
    },
    [HIDE_SINGLE_ORDER]({ commit }){
        commit(UNSET_SHOW_SINGLE_ORDER);
    },
    [PUSH_ORDER_TO_TARGET_STORE](context, {currentStoreId, currentOrderId, targetStoreId, shippingCost}){
        return new Promise((resolve) => {
            OrderApiService.pushOrderToTargetStore(currentStoreId, currentOrderId, targetStoreId, shippingCost)
                .then(({data}) => {
                    let pushStatus = data.order.push_status;
                    context.commit(UPDATE_ORDER_PUSH_STATUS_ORDER_LIST, { currentOrderId: currentOrderId, pushStatus: pushStatus } )
                    context.commit(SET_CURRENT_ORDER, data.order);
                    resolve(data);
                })
                .catch((response) => {
                    resolve(response.data);
                });
        });
    },
    [LAUNCH_STORE_ORDER_PUSHING_STATUS](context, targetStoreId){
        context.commit(SET_STORE_ORDER_PUSHING_STATUS, targetStoreId)
    },
    [AUTO_PUSH_TOGGLE](context, { storeId } ) {
        return new Promise((resolve) => {
            OrderApiService.autoPushToggle(storeId)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    resolve(response.data);
                });
        });
    },
    [FETCH_PUSH_SETTINGS](context, { storeId }) {
        return new Promise((resolve) => {
            OrderApiService.fetchPushSetting(storeId)
                .then(({ data }) => {
                    context.commit(SET_PUSH_SETTINGS, data.settings)
                    resolve(data);
                })
                .catch((response) => {
                    resolve(response.data);
                });
        })
    },
    [FETCH_ORDERS_LIST](context, {currentShopId, searchStr, sortBy, pageNumber, filters}) {
        context.commit(SET_LOADING);
        return new Promise((resolve) => {
            OrderApiService.getOrders(currentShopId, searchStr, sortBy, pageNumber, filters)
                .then(({ data }) => {
                    context.commit(APPEND_ORDERS_LIST, data.orders);
                    context.commit(SET_CURRENT_PAGE, data.orders.pagination.current_page)
                    context.commit(UNSET_LOADING);
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(UNSET_LOADING);
                    resolve(response);
                });
        });
    },
}

const mutations = {
    [SET_META_DATA](state, payload) {
      state.ordersMetaData = payload
    },
    [SET_ERROR](state, error) {
        state.errors.push(error);
    },
    [UNSET_ERROR](state) {
        state.errors = [];
    },
    [SET_ORDERS](state, orders) {
        state.orders = orders;
    },
    [UNSET_ORDERS_LIST](state) {
        state.orders.orders = [];
    },
    [SET_LOADING](state) {
        state.isLoading = true;
    },
    [UNSET_LOADING](state) {
        state.isLoading = false;
    },
    [SET_CURRENT_LOADING](state) {
        state.isLoadingCurrentOrder = true;
    },
    [UNSET_CURRENT_LOADING](state) {
        state.isLoadingCurrentOrder = false;
    },
    [SET_SHOW_SINGLE_ORDER](state) {
        state.showSingleOrder = true;
    },
    [UNSET_SHOW_SINGLE_ORDER](state) {
        state.showSingleOrder = false;
    },
    [SET_ORDERS_LIST](state, orders) {
        state.orders.orders = orders;
    },
    [SET_CURRENT_ORDER](state, order){
        state.currentOrder = order;
    },
    [UNSET_CURRENT_ORDER](state){
        state.currentOrder = null;
    },
    [SET_PUSH_SETTINGS](state, settings) {
        state.pushSettings = settings;
    },
    [SET_STORE_ORDER_PUSHING_STATUS](state, targetStoreId){
        Object.values(state.currentOrder.source_stores).forEach(value => {
            if(value.target_store_id === targetStoreId){
                value.push_status = 'pushing';
            }
        });
    },
    [APPEND_ORDERS_LIST](state, orders) {
        state.orders.orders = [...state.orders.orders, ...orders.orders]
    },
    [SET_CURRENT_PAGE](state, currentPage) {
        state.orders.pagination.current_page = currentPage;
    },
    [UPDATE_ORDER_PUSH_STATUS_ORDER_LIST](state, params){
        Object.values(state.orders.orders).forEach(order => {
            if(order.id === params.currentOrderId){
                order.push_status = params.pushStatus;
            }
        });
    }
}

const namespaced = true;

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
}