import axios from "axios";
import { filtersURL } from "@/utilities/filtersURL";

export const fetchPayouts = {
  async fetchPayouts({ commit }, { current_store_id, target_store_id, filters, limiter, page }) {
    let generatedFiltersURL = filtersURL.generate(filters);

    try {
      const response = await axios.get(`stores/payout/payout-orders/${current_store_id}/${target_store_id}?${generatedFiltersURL}`, {
        params: {
          limiter,
          page
        }
      });

      const { current_page, last_page, next_page_url, previous_page_url, total } = response.data;
      const pagination = {
        current_page,
        last_page,
        next_page_url,
        previous_page_url,
        total_count: total,
        per_page: limiter
      }

      commit('SET_PAYOUTS', response.data.payoutOrders || []);
      commit('SET_PAYOUTS_PAGINATION', pagination);
    } catch(error) {
      console.log(error);
    }
  }
}
