import axios from "axios";

export const createComment = {
  async createComment({ commit }, payload)  {
    try {
      const response = await axios.post('stores/payout/create-payout-comment', {
        ...payload
      });
    } catch (error) {
      console.log(error);
    }
  }
};
