import axios from "axios";

export const deletePayout = {
  async deletePayout({ commit }, payoutId)  {
    try {
      const response = await axios.delete(`stores/payout/delete-payout/${payoutId}`);
    } catch (error) {
      console.log(error);
    }
  }
};
