import axios from 'axios'
import { filtersURL } from '@/utilities/filtersURL'

export const fetchActivities = {
  async fetchActivities({ commit, rootState }, { filters, group, page, partner_store_id, search_str }) {
    let generatedFiltersURL = filtersURL.generate(filters)
    let url = `user/${rootState.auth.user_id}/sync-events?group=${group}`
    if(search_str !== '') url += `&search_str=${search_str}`
    if(partner_store_id) url += `&partner_store_id=${partner_store_id}`
    if(generatedFiltersURL !== '') url += `&${generatedFiltersURL}`
    if(page && typeof(page) === 'number') url += `&page=${page}`

    try {
      const response = await axios(url, {
        params: {
          limiter: 25,
        }
      })
      let payload = { response, type: rootState.activityCenter.selectedTab }
      commit('SET_ACTIVITIES', payload)
    } catch(error) {
      console.log(error)
    }
  }
}
