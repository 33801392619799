<template>
  <v-app class="grey lighten-4 app-wrapper" :class="{ 'banner-active' : isBannerVisible }">
    <article class="banner text-center" :class="banner.type" v-if="isBannerVisible">
      <p class="mb-0">{{ banner.topText }}</p>
      <p class="mb-0">{{ banner.bottomText }}</p>
      <v-btn fab x-small elevation="0" @click="hideBannerHandler">
        <v-icon color="rgba(0, 0, 0, 0.87)">mdi-close</v-icon>
      </v-btn>
    </article>
    <Navbar />
    <v-container class="primary-container">
      <router-view></router-view>
      <InformationDialog width="500px" title="Syncio Update" :showDialog="isRefreshPlatformAvailable">
        <h4 class="mb-5">Please click on the button below to update your Syncio app to be compatible with the latest version of Shopify.</h4>
        <h4 class="mb-5">Note: Updating Syncio will not change or impact your existing configuration.</h4>
        <v-btn :href="currentShop && currentShop.refresh_platform_key_url" height="48px" class="btn-primary mb-7 mt-3 px-5" elevation="0">Update my Syncio</v-btn>
        <p class="mb-0">For more information refer to <a href="http://help.syncio.co/en/articles/5855822-updating-syncio-for-the-latest-shopify-features" target="blank" class="link">this guide.</a></p>
        <p class="mb-0">or contact us at <a href="mailto:support@syncio.co">support@syncio.co</a></p>
      </InformationDialog>

      <UpdateAddonsStepOne />
      <UpdateAddonStepThree />

    </v-container>
    <processing-station></processing-station>
    <all-notification-component></all-notification-component>
  </v-app>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapState } from 'vuex';
import AllNotificationComponent from "@/views/components/AllNotificationComponent";
import InformationDialog from "@/components/InformationDialog.vue";
import Navbar from "@/views/components/Navbar";
import ProcessingStation from "@/views/components/ProcessingStation";
import axios from 'axios';
import UpdateAddonsStepOne from "@/components/UpdateAddonsStepOne.vue";
import UpdateAddonStepThree from '@/components/UpdateAddonStepThree.vue';

const { mapFields } = createHelpers({
  getterType: 'payouts/getField',
  mutationType: 'payouts/updateField'
});

export default {
  name: "SlidBarLayout",

  data() {
    return {
      banner: {
        bottomText: "",
        status: "",
        topText: "",
        type: ""
      }
    }
  },

  components: {
    AllNotificationComponent,
    InformationDialog,
    Navbar,
    ProcessingStation,
    UpdateAddonsStepOne,
    UpdateAddonStepThree,
  },

  computed: {
    ...mapState('shop', ['currentShop']),

    ...mapFields([
      "totalSourcePayouts"
    ]),

    isRefreshPlatformAvailable() {
      return this.currentShop && this.currentShop.refresh_platform_key_url !== null;
    },

    isBetaTester() {
      return this.currentShop && this.currentShop.beta_tester;
    },

    isBannerVisible() {
      return this.banner.status === "active" && localStorage[`isBannerActive_${this.currentShop?.id}`] === "active";
    }
  },

  watch: {
    'currentShop': {
      async handler(newValue, oldValue) {
        this.bootInterCom();
        await this.fetchBanner();
        if(this.isBetaTester) {
          let betaURL = 'http://syncio-v2-beta-app.syncio.io/shopify?shop=' + this.currentShop.store_domain;
          window.location.href = betaURL;
        }

        // if(newValue.type === "source") {
        //   let sourcePayouts = await this.$store.dispatch("payouts/fetchSourcePayouts", {
        //     current_store_id: this.currentShop.id,
        //     filters: [],
        //     page: 1
        //   });

        //   this.totalSourcePayouts = sourcePayouts.payoutsToConfirm;
        // }
      }
    },
  },

  methods: {
    async fetchBanner() {
      try {
        const response = await axios.get('/stores/get-banners')
        const { banner_text_bottom, banner_text_top, banner_type, status } = response.data.data;
        this.banner.bottomText = banner_text_bottom;
        this.banner.status = status;
        this.banner.topText = banner_text_top;
        this.banner.type = banner_type;
        if(localStorage.getItem(`isBannerActive_${this.currentShop.id}`) === "in-active") { return; }
        localStorage.setItem(`isBannerActive_${this.currentShop.id}`, this.banner.status);
      } catch(error) {
        console.log(error);
      }
    },

    hideBannerHandler() {
      this.banner.status = "in-active";
      localStorage.setItem(`isBannerActive_${this.currentShop.id}`, "in-active");
    },

    bootInterCom() {
      if(this.currentShop) {
        const createdAt = (new Date(this.currentShop.created_at).getTime()) / 1000;
        this.setInterComConfigs(this.currentShop, createdAt);
      }
    },

    setInterComConfigs(currentShop, createdAt) {
      window.intercomSettings = {
        app_id: process.env.VUE_APP_INTERCOM_APP_ID,
        created_at: createdAt,
        custom_launcher_selector: '.intercom-custom-launcher',
        email: currentShop.email,
        name: currentShop.store_domain,
        type: currentShop.type,
        user_id: currentShop.id
      };

      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget'+process.env.VUE_APP_INTERCOM_APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    }
  }
}
</script>
