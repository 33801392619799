import axios from "axios";

export const fetchPayoutEvents = {
  fetchPayoutEvents({ commit }, payload) {
    try {
      const response = axios.post("stores/payout/payout-events", {
        ...payload
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  }
};
