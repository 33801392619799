import moment from "moment";
import Vue from 'vue';

Vue.filter('limitCharacters', (value, charToShow) => {
  if(value.length > 40) {
    return value.substring(0, charToShow) + '...';
  }
  return value;
});

Vue.filter('currencyFormatter', (value, currency, trim) => {
  const money = new Intl.NumberFormat("en-US", { style: "currency", currency })
  let formattedValue = value ? money.format(value) : money.format(0);
  if(formattedValue.charAt(0) === '-') {
    formattedValue = formattedValue.substring(2)
    return formattedValue
  }
  return trim ? formattedValue.substring(1) : formattedValue
});

Vue.filter('humanReadableDateTime', (date) => {
  return moment(new Date(date).toLocaleString("en-US", { timeZone: "Australia/Sydney" })).format("MMMM D[,] hh:mm A");
});

Vue.filter('removeUnderscore', (value) => {
  if(value) {
    value = value.split("_").join(" ");
    value = value[0].toUpperCase() + value.substring(1);
    return value;
  }
});

Vue.filter('fixedDecimal', (value) => {
  if(typeof(value) === 'string') {
    value = +value
  }
  return value ? value.toFixed(2) : "";
});
