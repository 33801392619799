import axios from "axios";
import { filtersURL } from "@/utilities/filtersURL";

export const fetchSourcePayouts = {
  async fetchSourcePayouts({ commit }, { current_store_id, filters, limiter, page }) {
    let generatedFiltersURL = filtersURL.generate(filters);

    try {
      const response = await axios.get(`stores/payout/source-payouts/${current_store_id}?${generatedFiltersURL}`, {
        params: {
          limiter,
          page
        }
      });

      let formattedReponse = {
        pagination: {
          total_count: 0,
          per_page: limiter
        },
        payouts: [],
        payoutsToConfirm: 0
      };

      formattedReponse.payouts = [...formattedReponse.payouts, ...response.data?.payouts[0]];
      formattedReponse.pagination = {...formattedReponse.pagination, ...response.data?.payouts[1]};
      formattedReponse.pagination.total_count = response.data.payouts[1].total
      formattedReponse.payoutsToConfirm = response.data.payouts_to_confirm;

      commit('SET_SOURCE_PAYOUTS', formattedReponse);
      return formattedReponse;
    } catch (error) {
      console.log(error);
    }
  }
};
