import ProductApiService from "@/common/api/product.service";
import {
    SET_CURRENT_SHOP_PRODUCTS, SET_IS_LOADING_PRODUCTS_BY_SHOP, SET_CURRENT_VIEW_SHOP_PRODUCT,
    SET_SHOW_PRODUCT_DETAIL, SET_SHOW_PRODUCT_MAPPER, SET_PRODUCT_PAGE_ENTRY_SHOP_ID,
    SET_SELECTED_PRODUCT_SHOP_ID, SET_CURRENT_PARTNER_SHOP, SET_PRODUCT_PAGINATE,
    SET_PRODUCT_PAGEINATE_CURRENT_PAGE, SET_SELECTED_SHOP, SET_PRODUCT_META_OPTIONS
} from "@/store/mutations.type";
import { ADD_PRODUCT_ACTION_STATUS, FETCH_PRODUCTS_LIST, FETCH_PRODUCTS_BY_URL, FETCH_PRODUCT_META_OPTIONS } from "@/store/actions.type";

import { updateField, getField } from "vuex-map-fields";
import axios from 'axios';

const state = {
    currentShopProducts: [],
    isLoadingProductsByShop: false,
    currentViewShopProduct: null,
    showProductDetail: false,
    showProductMapper: false,
    productPageEntryShopId: null,
    selectProductShopId: null,
    currentPartnerShop: null,
    productActionStatusMapper: [],
    productPaginate: {
      current_page: 0,
      last_page: 0,
      search_path: null,
      limiter: 0,
      total: 0,
    },
    perPageRange: ["25", "50", "100"],
    selectedPerPage: "25",
    activeShop: {},
    bulkSync: false,
    productMetaOptions: {},
    isSyncLimitModalVisible: false,
    selectedSearchAttribute: "",
    refreshTimer: 15,
    refreshInterval: '',
};

const mutations = {
    [SET_CURRENT_SHOP_PRODUCTS](state, products) {
        state.currentShopProducts = products;
    },
    [SET_IS_LOADING_PRODUCTS_BY_SHOP](state, value) {
        state.isLoadingProductsByShop = value;
    },
    [SET_CURRENT_VIEW_SHOP_PRODUCT](state, product) {
        state.currentViewShopProduct = product;
    },
    [SET_SHOW_PRODUCT_DETAIL](state, value) {
        state.showProductDetail = value;
    },
    [SET_SHOW_PRODUCT_MAPPER](state, value) {
        state.showProductMapper = value;
    },
    [SET_PRODUCT_PAGE_ENTRY_SHOP_ID](state, value) {
        state.productPageEntryShopId = value;
    },
    [SET_SELECTED_PRODUCT_SHOP_ID](state, id) {
        state.selectProductShopId = id;
    },
    [SET_CURRENT_PARTNER_SHOP](state, shop) {
        state.currentPartnerShop = shop;
    },
    [SET_PRODUCT_PAGINATE](state, value) {
        state.productPaginate.current_page = value.current_page;
        state.productPaginate.last_page = value.last_page;
        state.productPaginate.search_path = value.search_path;
        state.productPaginate.limiter = value.limiter;
        state.productPaginate.total = value.total;
    },
    [SET_PRODUCT_PAGEINATE_CURRENT_PAGE](state, value) {
        state.productPaginate.current_page = value;
    },
    [SET_SELECTED_SHOP](state, payload) {
      state.activeShop = payload;
    },
    [SET_PRODUCT_META_OPTIONS](state, payload) {
      state.productMetaOptions = payload;
    }
};

const actions = {
    async fetchProductMetaOptions({ commit }, payload) {
      try {
        const res = await axios.get(`stores/${payload}/get-product-meta-fields`)
        commit(SET_PRODUCT_META_OPTIONS, { ...res.data?.meta_fields });
      } catch (error) {
        console.log(error)
      }
    },

    [FETCH_PRODUCTS_LIST]({ commit }, { sourceId, destinationId, searchStr, filters, sortBy, pageNumber, limiter, connection_id, metaFields, search_attribute, origin }) {
        new Promise((resolve) => {
            commit(SET_IS_LOADING_PRODUCTS_BY_SHOP, true);
            ProductApiService.loadProductsByShopId(sourceId, destinationId, searchStr, filters, sortBy, pageNumber, limiter, connection_id, metaFields, search_attribute, origin)
                .then(({ data }) => {
                    if (data.success) {
                        commit(SET_CURRENT_SHOP_PRODUCTS, data.products);
                        commit(SET_SELECTED_SHOP, data);
                        commit(SET_PRODUCT_PAGINATE, {
                            current_page: data.current_page,
                            last_page: data.last_page,
                            search_path: data.path,
                            limiter: data.limiter,
                            total: data.total
                        });
                    }
                    resolve();
                    commit(SET_IS_LOADING_PRODUCTS_BY_SHOP, false);
                })
                .catch((error) => {
                    console.log(error);
                    commit(SET_IS_LOADING_PRODUCTS_BY_SHOP, false);
                })
        });
    },
    [FETCH_PRODUCTS_BY_URL]({commit}, url){
        new Promise((resolve) => {
            commit(SET_IS_LOADING_PRODUCTS_BY_SHOP, true);
            ProductApiService.loadProductsByUrl(url)
            .then(({ data }) => {
                if (data.success) {
                    commit(SET_CURRENT_SHOP_PRODUCTS, data.products);
                    commit(SET_PRODUCT_PAGINATE, {
                        current_page: data.current_page,
                        last_page: data.last_page,
                        search_path: data.path,
                        limiter: data.limiter,
                        total: data.total,
                    });
                }
                resolve();
                commit(SET_IS_LOADING_PRODUCTS_BY_SHOP, false);
            })
            .catch((error) => {
                console.log(error);
                commit(SET_IS_LOADING_PRODUCTS_BY_SHOP, false);
            })
        });
    },
    [ADD_PRODUCT_ACTION_STATUS]({ state }, { connectionId, productId, statusValue, message }) {
        let identifier = connectionId + ':' + productId;
        let isDuplicated = false;
        state.productActionStatusMapper.forEach(($item) => {
            if ($item.key === identifier) {
                isDuplicated = true;
            }
        });
        if (!isDuplicated) {
            state.productActionStatusMapper.push({
                key: identifier,
                status: statusValue,
                message: message,
            });
        }
    },
    updateProductActionStatus({ state }, { connectionId, productId, statusValue, message }) {
        let identifier = connectionId + ':' + productId;
        state.productActionStatusMapper.forEach(($item) => {
            if ($item.key === identifier) {
                $item.status = statusValue;
                $item.message = message;
            }
        });
    },
    updateProductActionStatusByKey({ state }, { identifier, statusValue, message }) {
        state.productActionStatusMapper.forEach(($item) => {
            if ($item.key === identifier) {
                $item.status = statusValue;
                $item.message = message;
            }
        });
    },
    removeProductActionStatus({ state }, { connectionId, productId }) {
        let identifier = connectionId + ':' + productId;
        let removeIndex = null;
        state.productActionStatusMapper.forEach(($item, index) => {
            if ($item.key === identifier) {
                removeIndex = index;
            }
        });
        state.productActionStatusMapper.splice(removeIndex, 1);
    },
    removeProductActionStatusByKey({ state }, { identifier }) {
        let removeIndex = null;
        state.productActionStatusMapper.forEach(($item, index) => {
            if ($item.key === identifier) {
                removeIndex = index;
            }
        });
        state.productActionStatusMapper.splice(removeIndex, 1);
    }
};

const getters = {
    getField
}

mutations["updateField"] = updateField;

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
