<script>
export default {
  name: 'Logo'
}
</script>

<template>
  <svg width="151" height="52" viewBox="0 0 151 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7577 6.53816V0.918785C19.7577 0.310955 19.1 -0.0687218 18.5734 0.234901L1.17595 10.2795C0.649684 10.5834 0.649684 11.343 1.17595 11.6469L10.1953 16.8541C12.5537 14.4013 15.8663 12.8725 19.5377 12.8725C26.697 12.8725 32.5004 18.6759 32.5004 25.8352C32.5004 27.1344 32.307 28.3881 31.9515 29.5715L37.2698 32.6885C38.1502 30.4952 38.6358 28.1004 38.6358 25.5922C38.6358 15.127 30.2 6.63569 19.7577 6.53816Z" fill="#FB757C"/>
    <path d="M37.4184 39.9417L28.7534 34.9392C26.4031 37.3188 23.1402 38.7945 19.5312 38.7945C12.3722 38.7945 6.56853 32.9911 6.56853 25.8319C6.56853 25.0313 6.64487 24.2487 6.78391 23.4882L1.26933 20.256C0.776735 21.9486 0.511719 23.7378 0.511719 25.5892C0.511719 35.8689 8.65152 44.243 18.8366 44.6293V50.6699C18.8366 51.2777 19.4946 51.6574 20.0209 51.3535L37.4184 41.3092C37.9446 41.0053 37.9446 40.2456 37.4184 39.9417Z" fill="#FDB159"/>
    <path d="M11.6445 25.8228C11.6445 30.1783 15.1751 33.7095 19.5309 33.7095C23.8865 33.7095 27.4173 30.1783 27.4173 25.8228C27.4173 21.4673 23.8865 17.9361 19.5309 17.9361C15.1751 17.9361 11.6445 21.4673 11.6445 25.8228Z" fill="url(#paint0_linear_402_1050)"/>
    <path d="M119.017 27.4153C118.03 28.4023 116.668 29.0136 115.165 29.0136C112.16 29.0136 109.715 26.5686 109.715 23.5631C109.715 20.5579 112.16 18.113 115.165 18.113C116.668 18.113 118.03 18.7243 119.017 19.7112L121.688 17.041C120.018 15.3719 117.712 14.3394 115.165 14.3394C110.071 14.3394 105.941 18.4691 105.941 23.5631C105.941 28.6574 110.071 32.7871 115.165 32.7871C117.712 32.7871 120.018 31.7546 121.688 30.0853L119.017 27.4153Z" fill="#232323"/>
    <path d="M146.734 23.5634C146.734 22.1678 146.203 20.7719 145.14 19.7095C143.015 17.5844 139.557 17.5844 137.432 19.7095C135.307 21.8346 135.307 25.2923 137.432 27.4174C138.495 28.4798 139.89 29.0113 141.286 29.0113L141.286 32.7871C138.926 32.7871 136.565 31.8867 134.764 30.0855C131.162 26.4836 131.162 20.6433 134.764 17.0413C138.366 13.439 144.206 13.439 147.809 17.0413C149.609 18.8422 150.51 21.2029 150.51 23.5634H146.734Z" fill="#FB757C"/>
    <path d="M129.1 32.7876H125.035V15.5649H129.1V32.7876Z" fill="#232323"/>
    <path d="M130.019 9.90916C130.019 11.539 128.698 12.8604 127.068 12.8604C125.438 12.8604 124.117 11.539 124.117 9.90916C124.117 8.27928 125.438 6.95796 127.068 6.95796C128.698 6.95796 130.019 8.27928 130.019 9.90916Z" fill="#232323"/>
    <path d="M94.376 14.8589C89.336 14.8589 85.2426 18.9021 85.1564 23.9215H85.1523V33.1455H89.2162V24.0826C89.2162 21.2374 91.5308 18.9228 94.376 18.9228C97.2213 18.9228 99.5359 21.2374 99.5359 24.0826H103.6C103.6 18.9887 99.4703 14.8589 94.376 14.8589Z" fill="#232323"/>
    <path d="M103.599 32.7632H99.5352V24.0791H103.599V32.7632Z" fill="#FDB159"/>
    <path d="M63.5156 15.2373H67.5794V23.9214H63.5156V15.2373Z" fill="#FB757C"/>
    <path d="M77.9379 15.2101V23.9183H77.9031C77.9031 26.7635 75.5885 29.0782 72.7432 29.0782C69.898 29.0782 67.5833 26.7635 67.5833 23.9183H63.5195C63.5195 29.0126 67.6492 33.142 72.7432 33.142C74.67 33.142 76.4584 32.5504 77.9379 31.54V36.0351C77.9379 38.8804 75.6233 41.195 72.778 41.195C71.3554 41.195 70.0655 40.6162 69.1311 39.6821L66.2559 42.5572C67.925 44.2263 70.2309 45.2588 72.778 45.2588C77.872 45.2588 82.0017 41.1291 82.0017 36.0351V15.2101H77.9379Z" fill="#232323"/>
    <path d="M56.5663 27.6795C56.5663 26.9546 56.2679 26.4028 55.6714 26.0235C55.0746 25.6447 54.0851 25.31 52.7025 25.02C51.3197 24.73 50.1655 24.3619 49.2401 23.9161C47.2106 22.9347 46.1958 21.5129 46.1958 19.6505C46.1958 18.0895 46.8535 16.785 48.1696 15.7365C49.4851 14.6886 51.158 14.1641 53.1876 14.1641C55.351 14.1641 57.0987 14.6997 58.4313 15.7702C59.764 16.8407 60.4304 18.2288 60.4304 19.935H56.3657C56.3657 19.1545 56.0758 18.5048 55.4958 17.9864C54.9158 17.4677 54.1466 17.2085 53.1876 17.2085C52.2953 17.2085 51.5675 17.4151 51.0047 17.8273C50.4413 18.2401 50.16 18.7922 50.16 19.4833C50.16 20.108 50.4218 20.593 50.9464 20.9385C51.47 21.2845 52.5295 21.6328 54.1243 21.984C55.7187 22.3353 56.9707 22.7535 57.8792 23.2386C58.7881 23.7236 59.4629 24.3065 59.9033 24.9866C60.3439 25.6667 60.564 26.492 60.564 27.462C60.564 29.0902 59.8891 30.4086 58.5402 31.4179C57.1907 32.4271 55.4232 32.9316 53.2378 32.9316C51.7548 32.9316 50.4332 32.664 49.2735 32.1287C48.1136 31.5935 47.2106 30.8576 46.5638 29.9209C45.9171 28.9842 45.5938 27.975 45.5938 26.8934H49.5412C49.5969 27.8525 49.9592 28.5912 50.6285 29.1096C51.2976 29.6281 52.1838 29.8873 53.288 29.8873C54.3585 29.8873 55.1721 29.6841 55.7301 29.2768C56.2874 28.8699 56.5663 28.3375 56.5663 27.6795Z" fill="#232323"/>
    <path d="M141.286 18.1157C142.682 18.1157 144.078 18.6469 145.14 19.7096C147.265 21.8346 147.265 25.2924 145.14 27.4171C143.015 29.5422 139.557 29.5422 137.432 27.4171C136.37 26.3548 135.838 24.9591 135.838 23.5635L132.062 23.5632C132.062 25.924 132.963 28.2845 134.764 30.0856C138.366 33.6876 144.206 33.6876 147.808 30.0856C151.411 26.4833 151.411 20.6434 147.808 17.0411C146.007 15.2402 143.647 14.3395 141.286 14.3395V18.1157Z" fill="#232323"/>
    <defs>
      <linearGradient id="paint0_linear_402_1050" x1="12.9646" y1="32.1615" x2="25.1807" y2="20.3687" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FDAF5C"/>
        <stop offset="0.924847" stop-color="#FA747D"/>
        <stop offset="1" stop-color="#FA747D"/>
      </linearGradient>
    </defs>
  </svg>
</template>
