import { getField } from 'vuex-map-fields';

export const getters = {
  getField,

  GET_DESTINATION_STORE_LOCATIONS(state) {
    if(state.destinationStoreLocations.length > 0 && Array.isArray(state.destinationStoreLocations)) {
      return state.destinationStoreLocations.map(store => {
        return store = {
          id: store.id,
          name: store.name
        }
      });
    } else if(Object.keys(state.destinationStoreLocations).length > 0) {
      return Object.keys(state.destinationStoreLocations).map(key => {
        let store = {
          id: state.destinationStoreLocations[key].id,
          name: state.destinationStoreLocations[key].name,
        }
        return store;
      });
    } else {
      return [];
    }
  }
};
