import { getField } from 'vuex-map-fields';

export const getters = {
  getField,

  GET_CUSTOM_TAGS (state) {
    if(state.customShippingTags) {
      return Object.keys(state.customShippingTags.config).map(config => {
        return config = {
          isEditMode: false,
          isValid: true,
          tag: String(Object.values(state.customShippingTags.config[config])),
          title: config,
          updatedTag: ''
        };
      });
    }
  }
};
