import { SET_PROCESSING_QUEUE, SET_CURRENT_TASK } from "@/store/mutations.type";
import { ADD_ACTION, GET_NEXT_ACTION } from "@/store/actions.type";


const mutations = {
    [SET_PROCESSING_QUEUE](state, queue) {
        state.processingQueue = queue;
    },
    [SET_CURRENT_TASK](state, task) {
        state.currentTask = task;
    },
    pushActionToQueue(state, action) {
        state.processingQueue.push(action);
    }
};

const actions = {
    [ADD_ACTION]({ state, commit }, { actionType, param }) {
        if (state.processingQueue.length < 100) {
            commit('pushActionToQueue', {
                action: actionType,
                actionParam: param,
            });
        } else {
            console.log('maximum queued tasks reached.');
        }
    },
    [GET_NEXT_ACTION]({ state, commit }) {
        if (state.currentTask === null) {
            let task = state.processingQueue.pop();
            commit(SET_CURRENT_TASK, task);
        }
    },
}

export default {
    namespaced: true,
    state: {
        processingQueue: [],
        currentTask: null,
    },
    mutations,
    actions,
}