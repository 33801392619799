import axios from "axios";

export const sendMessage = {
  async sendMessage({ commit }, { message, storeId, target_store_id }) {
    const response = await axios.post(`stores/${storeId}/coco-profiles/connection-request`, {
      message,
      target_store_id
    })
  }
}
