import axios from "axios";

export const fetchProfiles = {
  async fetchProfiles({ commit, state, rootState }) {
    const { currentPage, limiter, queries } = state
    let storeType = rootState.shop.currentShop.type === 'destination' ? 'source' : 'destination'

    const { data: { success, profiles } } = await axios(`stores/coco-profiles`, {
      params: {
        ...queries,
        'filters[excluded_store_ids]': `${rootState.shop.currentShop.id}`,
        'filters[store_type]': storeType,
        limiter: limiter,
        page: currentPage,
      }
    })

    if(success) {
      const { current_page, next_page_url, prev_page_url, total } = profiles
      const pagination = {
        current_page: current_page,
        next_page_url: next_page_url,
        previous_page_url: prev_page_url,
        per_page: limiter,
        total_count: total,
      }
      commit('SET_PROFILES', profiles.data)
      commit('SET_PAGINATION', pagination)
    }
  }
}
