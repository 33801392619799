<template>
  <v-overlay :value="showDialog" class="dialog dialog-disconnect">
    <v-card light class="card-rounded not-a-link" :width="width">
      <v-toolbar height="71">
        <v-container class="px-0 d-flex justify-space-between">
          <v-card-title class="h3 pa-0">
            {{ title }}
          </v-card-title>
          <slot name="closeDialog"></slot>
        </v-container>
      </v-toolbar>

      <div class="d-block dialog-body pa-7 text-center">
        <slot></slot>
      </div>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  name: 'InformationDialog',

  props: ['title', 'showDialog', 'width']
}
</script>
