import axios from "axios";

export const updatePayout = {
  async updatePayout({ commit }, payload) {
    let response = null
    try {
      response = await axios.post("stores/payout/update-payout-status", {
        ...payload
      });
      return response
    } catch (error) {
      return response
    }
  }
};
