import AuthApiService from "@/common/api/auth.service";
import ApiService from "@/common/api/base.service";
import JwtService from "@/common/jwt.service";
import SessionData from "@/common/sessionData.service";
import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
  REGISTER,
  LOGIN_SHOPIFY,
  SAVE_SHOPIFY_TOKEN,
  REMOVE_ERRORS,
  RELOGIN_SHOPIFY,
  LOGIN_WOO
} from "@/store/actions.type";
import {
  SET_AUTH,
  PURGE_AUTH,
  SET_ERROR,
  UNSET_ERROR,
  SET_LOADING,
  UNSET_LOADING,
  IS_PASSWORD_ERROR,
  IS_EMAIL_ERROR
} from "@/store/mutations.type";

const initialState = {
  user_id: SessionData.getUserID(),
  errors: [],
  isAuthenticated: !!JwtService.getToken(),
  user: {},
  isLoading: false,
  showAddonsDialog: false,
  showUpdateAddonsDialogOne: true,
  showUpdateAddonsDialogTwo: false,
  showUpdateAddonsDialogThree: false,
  isPasswordError: false,
  isEmailError: false,
};

import { updateField } from "vuex-map-fields";
import { getField } from "vuex-map-fields";

export const state = { ...initialState };

const getters = {
  getField,

  currentUserID(state) {
    return state.user.id;
  },
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isLoading(state) {
    return state.isLoading;
  },
  errors(state) {
    return state.errors;
  }
};

const actions = {
  [REMOVE_ERRORS](context) {
    context.commit(UNSET_ERROR);
  },
  [LOGIN](context, { email, password }) {
    context.commit(SET_LOADING);
    context.commit(UNSET_ERROR);
    return new Promise(resolve => {
      AuthApiService.login(email, password)
        .then(response => {
          if(response === undefined) {
            context.commit(IS_PASSWORD_ERROR);
            context.commit(UNSET_LOADING);
            return
          }
          context.commit(SET_AUTH, response);
          context.commit(UNSET_LOADING);
          resolve(response.data);
        })
        .catch(response => {
          context.commit(IS_EMAIL_ERROR);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(UNSET_LOADING);
        });
    });
  },
  async [LOGIN_WOO](context, { jwt, store }) {
    context.commit(SET_LOADING);
    const res = await AuthApiService.wooLogin(jwt, store);
    const { data } = res;
    context.commit(SET_AUTH, res);
    context.commit(UNSET_LOADING);
    return data;
  },
  //This action will work for login and register both, since we dont have seprate route for login and register from shopify
  [LOGIN_SHOPIFY](context, { storeName }) {
    context.commit(SET_LOADING);
    return new Promise(resolve => {
      AuthApiService.shopifyLogin(storeName, "shopify")
        .then(response => {
          if (typeof response.data.redirect_url === "undefined") {
            context.commit(SET_AUTH, response);
          }
          context.commit(UNSET_LOADING);
          resolve(response.data);
        })
        .catch(response => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(UNSET_LOADING);
        });
    });
  },
  [RELOGIN_SHOPIFY](context, { storeName }) {
    context.commit(SET_LOADING);
    return new Promise(resolve => {
      AuthApiService.shopifyRelogin(storeName, "shopify")
        .then(response => {
          if (typeof response.data.redirect_url === "undefined") {
            context.commit(SET_AUTH, response);
          }
          context.commit(UNSET_LOADING);
          resolve(response.data);
        })
        .catch(response => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(UNSET_LOADING);
        });
    });
  },
  [SAVE_SHOPIFY_TOKEN](context, payLoad) {
    context.commit(SET_LOADING);
    return new Promise((resolve, reject) => {
      AuthApiService.saveShopifyToken(payLoad)
        .then(response => {
          resolve(response);
          context.commit(UNSET_LOADING);
        })
        .catch(response => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
          context.commit(UNSET_LOADING);
        });
    });
  },
  [REGISTER](context, { name, email, password, password_confirmation }) {
    context.commit(SET_LOADING);
    context.commit(UNSET_ERROR);
    return new Promise((resolve, reject) => {
      AuthApiService.registerUser(name, email, password, password_confirmation)
        .then(response => {
          context.commit(SET_AUTH, response);
          context.commit(UNSET_LOADING);
          resolve(response);
        })
        .catch(response => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
          context.commit(UNSET_LOADING);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise(resolve => {
      context.commit(PURGE_AUTH);
      resolve();
      localStorage.removeItem(
        `isBannerActive_${context.rootState.shop.currentShop.id}`
      );
    });
  },
  [CHECK_AUTH](context) {
    return new Promise(resolve => {
      if (JwtService.getToken() && SessionData.getUserID()) {
        ApiService.setHeader();
        AuthApiService.getUser(SessionData.getUserID())
          .then(response => {
            context.commit(SET_AUTH, response);
            resolve(response);
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            resolve();
          });
      } else {
        context.commit(PURGE_AUTH);
        resolve();
      }
    });
  }
};

const mutations = {
  [IS_PASSWORD_ERROR](state) {
    state.isPasswordError = true
  },
  [IS_EMAIL_ERROR](state) {
    state.isEmailError = true
  },
  [SET_ERROR](state, error) {
    Object.keys(error).forEach(function(key) {
      if (key == 0) {
        state.errors.push(error[key]);
      } else {
        state.errors.push(error[key][0]);
      }
    });
  },
  [UNSET_ERROR](state) {
    state.errors = [];
  },
  [SET_LOADING](state) {
    state.isLoading = true;
  },
  [UNSET_LOADING](state) {
    state.isLoading = false;
  },
  [SET_AUTH](state, response) {
    state.isAuthenticated = true;
    state.user = response.data.user;
    state.errors = [];
    state.user_id = response.data && response.data.user && response.data.user.id;
    if (response.data.popup === undefined) {
      state.showAddonsDialog = false;
    } else {
      state.showAddonsDialog = response.data.popup;
    }
    SessionData.saveUserID(state.user_id);
    JwtService.saveToken(response.headers["x-syncio-app-token"]);
    ApiService.setHeader();
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.errors = [];
    JwtService.destroyToken();
    SessionData.destroyUserID();
  }
};

mutations["updateField"] = updateField;

const namespaced = true;

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
