import ApiService from "@/common/api/base.service";

/**
 * Products related API to the backend
 */
export default {
    loadProductsByShopId(sId, dId, searchStr, filters, sortByParam, pageNumber, limiter, connection_id, metaFields, search_attribute, origin) {
        let metaFieldsURL = '';
        if (metaFields != null && typeof (metaFields) === "object" && metaFields.length > 0) {
          metaFields.forEach(field => {
            if(Object.values(field)[0] && Object.values(field)[0].toLocaleLowerCase() !== 'all') {
              metaFieldsURL += (`&meta_fields[${Object.keys(field)[0]}]=${Object.values(field)[0]}`);
            }
          });
        }

        let url = "";

        if(search_attribute !== undefined) {
          url = "products?" + "source_store_id=" + sId + "&" + "destination_store_id=" + dId + "&" + "limiter=" + limiter + "&connection_id=" + connection_id + metaFieldsURL + "&search_attribute=" + search_attribute;
        } else {
          url = "products?" + "source_store_id=" + sId + "&" + "destination_store_id=" + dId + "&" + "limiter=" + limiter + "&connection_id=" + connection_id + metaFieldsURL;
        }

        if (searchStr != null) {
            url += "&search_str=" + encodeURIComponent(searchStr);
        }
        if (filters != null && typeof (filters) === "object" && filters.length > 0) {
            filters.forEach(filter => {
                url += "&filters[]=" + filter;
            });
        }

        if (sortByParam != null && typeof (sortByParam) === "object" ) {
            url += "&sort_by=" + sortByParam.sort_by;
            url += "&sort_by_desc=" + sortByParam.sort_by_desc;
        }

        url += "&page=" + pageNumber;
        url += "&origin=" + origin;

        return ApiService.get(url);
    },
    loadProductsByUrl(url) {
        return ApiService.get(url);
    },
    loadProductOnShop(sourceStoreId, destinationStoreId, externalProductId) {
        return ApiService.get('products/search-by-id?store_id='+sourceStoreId+'&product_id='+externalProductId+'&destination_store_id='+destinationStoreId);
    },
    importProduct(connectionId, sourceProductId) {
        return ApiService.post('products/import-now', {
            connection_id: connectionId,
            source_product_id: sourceProductId,
        });
    },
    bulkProductsImport(connectionId, sourceProductIds) {
        return ApiService.post('products/import-now', {
            connection_id: connectionId,
            source_product_ids: sourceProductIds,
        });
    },
    unsyncProduct(mapperId, keepingProducts) {
        return ApiService.post('products/unsync', {
            mapper_ids: [ mapperId ],
            keeping_products: keepingProducts,
        });
    },
    searchProduct(shopId, searchStr) {
        // removing * from start and end of search term.
        searchStr = searchStr.replace(/^\*+|\*+$/g, '');
        return ApiService.get('products/search-store-products?store_id='+shopId+'&search_str='+encodeURIComponent(searchStr));
    },
    syncAllProducts(connectionId, sourceProductIds) {
        return ApiService.post('products/import', {
            connection_id: connectionId,
            source_product_ids: sourceProductIds
        });
    },
    mapProducts(sourceShopId, destinationShopId, sourceProductId, destinationProductId) {
        return ApiService.post('products/products-mapping', {
            source_store_id: sourceShopId,
            destination_store_id: destinationShopId,
            source_product_id: sourceProductId,
            destination_product_id: destinationProductId,
        });
    },
    resyncProduct(mapperId) {
        return ApiService.post('products/sync', {
            mapper_ids: [mapperId],
        });
    },
    loadProductInventoryByLocation(sourceStoreId, productId, locationId) {
        return ApiService.post('shopify/product-inventory-count', {
            source_store_id: sourceStoreId,
            product_id: productId,
            location_id: locationId,
        });
    },
    bulkSmartProductMapping(sourceStoreId, destinationStoreId) {
        return ApiService.get('products/bulk-smart-mapping?source_store_id='+sourceStoreId+'&destination_store_id='+destinationStoreId);
    }
};