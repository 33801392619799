const USER_ID = "user_id";

export const getUserID = () => {
    return sessionStorage.getItem(USER_ID);
};

export const saveUserID = userid => {
    sessionStorage.setItem(USER_ID, userid);
};

export const destroyUserID = () => {
    sessionStorage.removeItem(USER_ID);
};

export default { getUserID, saveUserID, destroyUserID };