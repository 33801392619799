import axios from "axios";

export const createPayout = {
  async createPayout({ commit }, payload)  {
    try {
      const response = await axios.post('stores/payout/create-payout', {
        ...payload
      });
    } catch (error) {
      console.log(error);
    }
  }
};
