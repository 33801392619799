import deepmerge from "deepmerge";
import { updateField } from 'vuex-map-fields';

/* Actions */
import { bulkPush } from "./actions/bulkPush";
import { createOrderMessages } from "./actions/createOrderMessages";
import { fetchCustomShippingTags } from "./actions/fetchCustomShippingTags";
import { fetchOrderMessages } from "./actions/fetchOrderMessages";
import { updateCustomShippingTags } from "./actions/updateCustomShippingTags";

/* Mutations */
import { setCustomShippingTags } from "./mutations/setCustomShippingTags";

const state = {
  customShippingTags: null
};

const actions = deepmerge.all([
  bulkPush,
  createOrderMessages,
  fetchCustomShippingTags,
  fetchOrderMessages,
  updateCustomShippingTags
]);

const mutations = deepmerge.all([
  setCustomShippingTags,
  {}
]);

mutations['updateField'] = updateField

import { getters } from "./getters";

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
};
