import axios from 'axios'

export const dismissIssue = {
  async dismissIssue({ commit, rootState }, { activityId }) {
    try {
      const response = await axios.delete(`user/${rootState.auth.user_id}/sync-events/${activityId}`)
    } catch (error) {
      console.log(error)
    }
  }
}
