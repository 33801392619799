import axios from "axios";
import { filtersURL } from "@/utilities/filtersURL";

export const fetchRecords = {
  async fetchRecords({ commit }, { storeId, filters, limiter, page }) {
    let generatedFiltersURL = filtersURL.generate(filters);

    try {
      const response = await axios.get(`stores/payout/all-payout-data/${storeId}?${generatedFiltersURL}`, {
        params: {
          limiter,
          page
        }
      });

      const { current_page, last_page, next_page_url, previous_page_url, total } = response.data;
      const pagination = {
        current_page,
        last_page,
        next_page_url,
        per_page: limiter,
        previous_page_url,
        total_count: total,
      }

      commit('SET_RECORDS', response.data.payouts || []);
      commit('SET_RECORDS_PAGINATION', pagination);
    } catch(error) {
      console.log(error);
    }
  }
};
