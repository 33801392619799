import ApiService from "@/common/api/base.service";

export const updateCustomShippingTags = {
  async updateCustomShippingTags({ commit }, { store_id, custom_shipping_tags }) {
    try {
      const response = await ApiService.post(`stores/${store_id}/orders/custom-shipping-tags/update`, {
        custom_shipping_tags
      });
    } catch(error) {
      console.log(error);
    }
  }
};
