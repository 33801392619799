import { getters } from './getters'
import { updateField } from 'vuex-map-fields'
import deepmerge from 'deepmerge'

/* Actions */
import { addDefaultStoreCommission } from './actions/addDefaultStoreCommission'
import { addStoreCommission } from './actions/addStoreCommission'
import { deleteCommission } from './actions/deleteCommission'
import { fetchByStore } from './actions/fetchByStore'
import { fetchByProduct } from './actions/fetchByProduct'

/* Mutations */
import { setConnections } from './mutations/setConnections'
import { setProducts } from './mutations/setProducts'
import { setProductsPagination } from './mutations/setProductsPagination'

const state = {
  connections: [],
  connectionsPagination: null,
  defaultCommission: { type: null, value: null },
  isCommissionModalVisible: false,
  isSaveDefaultCommissionBtnVisible: false,
  isSaveProductCommissionBtnVisible: false,
  isSaveStoreCommisionBtnVisible: false,
  isUnsavedModalVisible: false,
  productFilters: { search_str: null, source_store_id: null, connection_id: null },
  products: [],
  productsCurrentPage: '',
  productsPagination: null,
  searchByStoreStr: null,
  selectedProductCommissions: [],
  selectedStoreCommissions: [],
  selectedTab: 'default',
  storeFilters: { search_str: null, sort_by: null, sort_by_desc: null },
  tabs: [ { value: 'default', label: 'Your Shop Default' }, { value: 'store', label: 'By Store' }, { value: 'product', label: 'By Product' } ],
  unMutatedConnections: [],
  unMutatedDefaultCommission: { type: null, value: null },
  unMutatedProducts: [],
}

const actions = deepmerge.all([
  addDefaultStoreCommission,
  addStoreCommission,
  deleteCommission,
  fetchByStore,
  fetchByProduct,
])

const mutations = deepmerge.all([
  setConnections,
  setProducts,
  setProductsPagination,
])

mutations['updateField'] = updateField

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
