<template>
  <aside class="products-sync-counter mt-2">
    <v-card class="card-rounded not-a-link pa-4">
      <h2 class="text-uppercase mt-0 mb-3 font-weight-bold">Products Synced</h2>
      <div v-if="activePlan != null && typeof (activePlan.product_mappers_count) != 'undefined'">
        <div class="products-sync-counter__outer relative">
          <div class="products-sync-counter__inner absolute" :style="{width: planUsedPercentage + '%'}"></div>
        </div>
        <div class="text-right mt-1">{{ activePlan.product_mappers_count }}/{{ activePlan.syncio_plan.sync_product_limit }}</div>
      </div>
      <router-link v-if="this.currentShop.platform === 'shopify'" tag="button" to="/account/billing" class="upgrade-btn mt-5">Upgrade</router-link>
      <a v-if="currentShop && currentShop.platform === 'woocommerce'" :href="wooPlanSelectionLink" class="upgrade-btn mt-5">Upgrade</a>
    </v-card>
  </aside>
</template>

<script>
import { mapState } from 'vuex'
import JwtService from "@/common/jwt.service";

export default {
  name: 'ProductsSyncCounter',
  props: ['activePlan'],
  computed: {
    planUsedPercentage() {
      if(this.activePlan) {
        return (this.activePlan.product_mappers_count * 100) / this.activePlan.syncio_plan.sync_product_limit;
      } else {
        return 0;
      }
    },
    wooPlanSelectionLink() {
      return `${ process.env.VUE_APP_WOO_BILLING}/?jwt=${JwtService.getToken() }`
    },
    ...mapState('shop', ['currentShop']),
  }
}
</script>
<style lang="scss" scoped>
  .products-sync-counter {
    bottom: 16px;
    left: 16px;
    position: absolute;
    width: calc(100% - 32px);

    .card-rounded {
      border-radius: 10px !important;
    }

    h2 {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: .2px !important;
      color: #0E3B4D;
    }

    &__outer{
      background: #FFD7D8;
      border-radius: 5px;
      height: 10px;
      overflow: hidden;
      width: 100%;
    }

    &__inner {
      background: #FA757B;
      border-radius: 5px;
      height: 100%;
      left: 0;
      top: 0;
    }

    .text-right {
      font-size: 14px;
      line-height: 20px;
      color: #0E3B4D;
    }
  }
</style>
