import axios from "axios";

export const fetchByProduct = {
  async fetchByProduct ({ commit }, payload) {
    try {
      const response = await axios.get('products', {
        params: { ...payload }
      })

      if(response.data.success) {
        response.data.products.forEach(product => { product.isSelected = false })
        commit('SET_PRODUCTS', response.data.products)
        const { current_page, next_page_url, previous_page_url, total, limiter } = response.data
        const pagination = {
          current_page: current_page,
          next_page_url: next_page_url,
          previous_page_url: previous_page_url,
          per_page: limiter,
          total_count: total
        }
        commit('SET_PRODUCTS_PAGINATION', pagination)
        return response
      }
    } catch(error) {
      return error
    }
  }
}
