import ApiService from "@/common/api/base.service";
import httpBuildQuery from "http-build-query";

export default {
    fetchActivityLogList(filters, limiter) {
        let uri = '';
        if (filters != null) {
            uri = httpBuildQuery({filters, limiter});
        } else {
            uri = httpBuildQuery({
                filters: {
                    none: '',
                },
                limiter: limiter
            });

        }
        return ApiService.get('user/activity-logs?'+uri);

    },
    fetchActivityLogsByUrl (url) {
        return ApiService.get(url)
    },
    loadAvailableTypes() {
        return ApiService.get("user/activity-types");
    }
};