import axios from "axios";

export const fetchProfile = {
  async fetchProfile({commit}, storeId) {
    const { data: { success, profile } } = await axios(`stores/${storeId}/coco-profile`)
    if(success) {
      commit('SET_PROFILE', profile)
    }
  }
}
