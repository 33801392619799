<template>
  <nav class="prmary-header">
    <v-app-bar app flat color="white" clipped-left height="53">
      <v-toolbar-title>
        <v-img height="40" width="115" src="@/assets/images/syncio_logo_full.svg"></v-img>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="toolbar-account user-options-list">
        <span class="avator px-4 text-capitalize font-weight-bold" :class="shortName">{{ shortName }}</span>
        <span class="pr-1 pl-4 user-info ml-3" v-if="currentShop != null" :class="{ active: isMenuExpanded }">
          <v-menu
            offset-y
            attach=".user-options-list"
            v-model="isMenuExpanded"
            max-width="168"
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                class="store-name flex align-center"
                @click="userDropDownHandler()"
                v-bind="attrs"
                v-on="on"
              >
                {{ currentShop.store_domain }}
                <v-icon size="30">arrow_drop_down</v-icon>
              </span>
            </template>
            <v-list
              class="py-0"
              v-if="currentShop && currentShop.platform === 'shopify'"
            >
              <v-list-item
                v-for="(link, index) in dropDownLinks"
                :key="index"
                :to="link.route"
                :class="{ 'logout-link': link.text === 'Logout' }"
                class="flex justify-space-around"
              >
                <template>
                  <v-list-item-title>
                    {{ link.text }}
                  </v-list-item-title>
                  <v-icon size="20" v-if="link.icon !== ''">{{
                    link.icon
                  }}</v-icon>
                </template>
              </v-list-item>
            </v-list>
            <v-list
              class="py-0"
              v-if="currentShop && currentShop.platform === 'woocommerce'"
              :class="currentShop.platform"
            >
              <v-list-item
                to="/settings/account/general"
                class="flex justify-space-around"
              >
                <v-list-item-title>Account Settings</v-list-item-title>
              </v-list-item>
              <a
                v-if="currentShop.type === 'destination'"
                :href="wooPlanSelectionLink"
                class="flex justify-space-around v-list-item v-list-item--link theme--light"
                tabindex="0"
                role="menuitem"
              >
                <div class="v-list-item__title">Plan &amp; Billing</div>
              </a>
              <v-list-item
                to="/logout"
                class="flex justify-space-around logout-link"
              >
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </span>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      :class="currentShop && currentShop.type"
      app
      class="primary"
      clipped
      permanent
      v-model="drawer"
      width="220">
      <v-list v-if="currentShop && currentShop.type === 'destination'" class="pa-0">
        <v-list-item to="/dashboard">
          <v-list-item-icon class="mr-3">
            <v-icon class="white--text">dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/marketplace">
          <v-list-item-icon class="mr-3">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="marketplace-icon">
              <path d="M19.5 8.25H16.5V7.75C16.5 6.55653 16.0259 5.41193 15.182 4.56802C14.3381 3.72411 13.1935 3.25 12 3.25C10.8065 3.25 9.66193 3.72411 8.81802 4.56802C7.97411 5.41193 7.5 6.55653 7.5 7.75V8.25H4.5C4.16848 8.25 3.85054 8.3817 3.61612 8.61612C3.3817 8.85054 3.25 9.16848 3.25 9.5V18C3.25 18.7293 3.53973 19.4288 4.05546 19.9445C4.57118 20.4603 5.27065 20.75 6 20.75H18C18.7293 20.75 19.4288 20.4603 19.9445 19.9445C20.4603 19.4288 20.75 18.7293 20.75 18V9.5C20.75 9.16848 20.6183 8.85054 20.3839 8.61612C20.1495 8.3817 19.8315 8.25 19.5 8.25ZM9 7.75C9 6.95435 9.31607 6.19129 9.87868 5.62868C10.4413 5.06607 11.2044 4.75 12 4.75C12.7956 4.75 13.5587 5.06607 14.1213 5.62868C14.6839 6.19129 15 6.95435 15 7.75V8.25H9V7.75ZM19.25 18C19.25 18.3315 19.1183 18.6495 18.8839 18.8839C18.6495 19.1183 18.3315 19.25 18 19.25H6C5.66848 19.25 5.35054 19.1183 5.11612 18.8839C4.8817 18.6495 4.75 18.3315 4.75 18V9.75H7.5V12C7.5 12.1989 7.57902 12.3897 7.71967 12.5303C7.86032 12.671 8.05109 12.75 8.25 12.75C8.44891 12.75 8.63968 12.671 8.78033 12.5303C8.92098 12.3897 9 12.1989 9 12V9.75H15V12C15 12.1989 15.079 12.3897 15.2197 12.5303C15.3603 12.671 15.5511 12.75 15.75 12.75C15.9489 12.75 16.1397 12.671 16.2803 12.5303C16.421 12.3897 16.5 12.1989 16.5 12V9.75H19.25V18Z" fill="#ffffff"/>
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            <v-list-item-title>Syncio Marketplace</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/shops">
          <v-list-item-icon class="mr-3">
            <v-icon class="white--text">insert_link</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            <v-list-item-title>Stores</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/products">
          <v-list-item-icon class="mr-3">
            <v-icon class="white--text">format_list_bulleted</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            <v-list-item-title>Products</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="currentShop.platform === 'shopify'" to="/orders" :class="{ 'addon-not-added': !isOrderModuleAvailable }">
          <v-list-item-icon class="mr-3">
            <v-icon class="white--text">inventory_2</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            <v-list-item-title>Orders</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="!isOrderModuleAvailable">
            <v-icon class="white--text">lock</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item v-if="currentShop.platform === 'shopify'" to="/payouts" :class="{ 'addon-not-added': !isPayoutsModuleAvailable }">
          <v-list-item-icon class="mr-3">
            <v-icon class="white--text">receipt_long</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            <v-list-item-title>Payouts</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="!isPayoutsModuleAvailable">
            <v-icon class="white--text">lock</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item v-if="currentShop.platform === 'shopify'" to="/activity-center">
          <v-list-item-icon class="mr-3">
            <v-icon class="white--text">notifications</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            <v-list-item-title>Activity Center</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/settings">
          <v-list-item-icon class="mr-3">
            <v-icon class="white--text">settings</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list v-else class="pa-0">
        <v-list-item to="/dashboard">
          <v-list-item-icon class="mr-3">
            <v-icon class="white--text">dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/marketplace">
          <v-list-item-icon class="mr-3">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="marketplace-icon">
              <path d="M19.5 8.25H16.5V7.75C16.5 6.55653 16.0259 5.41193 15.182 4.56802C14.3381 3.72411 13.1935 3.25 12 3.25C10.8065 3.25 9.66193 3.72411 8.81802 4.56802C7.97411 5.41193 7.5 6.55653 7.5 7.75V8.25H4.5C4.16848 8.25 3.85054 8.3817 3.61612 8.61612C3.3817 8.85054 3.25 9.16848 3.25 9.5V18C3.25 18.7293 3.53973 19.4288 4.05546 19.9445C4.57118 20.4603 5.27065 20.75 6 20.75H18C18.7293 20.75 19.4288 20.4603 19.9445 19.9445C20.4603 19.4288 20.75 18.7293 20.75 18V9.5C20.75 9.16848 20.6183 8.85054 20.3839 8.61612C20.1495 8.3817 19.8315 8.25 19.5 8.25ZM9 7.75C9 6.95435 9.31607 6.19129 9.87868 5.62868C10.4413 5.06607 11.2044 4.75 12 4.75C12.7956 4.75 13.5587 5.06607 14.1213 5.62868C14.6839 6.19129 15 6.95435 15 7.75V8.25H9V7.75ZM19.25 18C19.25 18.3315 19.1183 18.6495 18.8839 18.8839C18.6495 19.1183 18.3315 19.25 18 19.25H6C5.66848 19.25 5.35054 19.1183 5.11612 18.8839C4.8817 18.6495 4.75 18.3315 4.75 18V9.75H7.5V12C7.5 12.1989 7.57902 12.3897 7.71967 12.5303C7.86032 12.671 8.05109 12.75 8.25 12.75C8.44891 12.75 8.63968 12.671 8.78033 12.5303C8.92098 12.3897 9 12.1989 9 12V9.75H15V12C15 12.1989 15.079 12.3897 15.2197 12.5303C15.3603 12.671 15.5511 12.75 15.75 12.75C15.9489 12.75 16.1397 12.671 16.2803 12.5303C16.421 12.3897 16.5 12.1989 16.5 12V9.75H19.25V18Z" fill="#ffffff"/>
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            <v-list-item-title>Syncio Marketplace</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/shops">
          <v-list-item-icon class="mr-3">
            <v-icon class="white--text">insert_link</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            <v-list-item-title>Stores</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/products">
          <v-list-item-icon class="mr-3">
            <v-icon class="white--text">format_list_bulleted</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            <v-list-item-title>Products</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="currentShop && currentShop.platform === 'shopify'" to="/source-payouts">
          <v-list-item-icon class="mr-3">
            <v-icon class="white--text">receipt_long</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            <v-list-item-title>Payouts</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="currentShop && currentShop.type === 'source'">
            <span class="white-text payout-count-indicator">{{ currentShop.payout_count }}</span>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item to="/settings">
          <v-list-item-icon class="mr-3">
            <v-icon class="white--text">settings</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <products-sync-counter v-if="currentShop != null && currentShop.type !== 'source'" :activePlan="currentActivePlan"/>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ProductsSyncCounter from "./ProductSyncCounter.vue";
import JwtService from "@/common/jwt.service";

export default {
  name: "Navbar",
  data() {
    return {
      drawer: true,
      dropDownDestinationLinks: [
        { icon: "", text: "Account Details", route: "/settings/account/general" },
        { icon: "", text: "Plan and Billing", route: "/account/billing" },
        { icon: "", text: "Logout", route: "/logout" },
      ],
      dropDownSourceLinks: [
        { icon: "", text: "Account Settings", route: "/settings/account/general" },
        { icon: "", text: "Logout", route: "/logout" },
      ],
      destinationLinks: [],
      sourceLinks: [],
      activePlan: {},
      isMenuExpanded: false,
    };
  },
  components: {
    ProductsSyncCounter,
  },
  mounted: function() {
    this.$store.dispatch("plan/getCurrentPlan");
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("shop", ["currentShop"]),
    ...mapState("plan", ["currentActivePlan"]),
    ...mapGetters("shop", [
      "isLocationPending",
      "isPayoutsAvailableForSourceStore",
    ]),
    ...mapGetters("plan", [
      "isOrderModuleAvailable",
      "isPayoutsModuleAvailable",
    ]),
    shortName() {
      if (this.currentShop != null) {
        return this.currentShop.type;
      }
      return "N/A";
    },
    links() {
      if (this.currentShop != null && this.currentShop.type != "source") {
        return this.destinationLinks;
      }
      return this.sourceLinks;
    },
    dropDownLinks() {
      if (this.currentShop != null && this.currentShop.type != "source") {
        return this.dropDownDestinationLinks;
      }
      return this.dropDownSourceLinks;
    },
    activeAddons() {
      if (this.currentActivePlan && this.currentActivePlan.active_addons) {
        return this.currentActivePlan.active_addons.map((addon) => {
          return addon.name;
        });
      } else {
        return [];
      }
    },
    wooPlanSelectionLink() {
      return `${process.env.VUE_APP_WOO_BILLING}/?jwt=${JwtService.getToken()}`;
    },
  },
  methods: {
    userDropDownHandler() {
      this.isMenuExpanded = !this.isMenuExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.primary {
  .v-list-item__icon {
    max-width: 24px !important;
  }

  &.destination {
    a.v-list-item--active {
      background: #082835;

      .v-icon {
        color: #ff6b7a !important;
      }

      .marketplace-icon path {
        fill: #ff6b7a !important;
      }

      .v-list-item__title {
        font-weight: bold;
      }
    }

    .location-error-indication {
      color: #ff6b7a !important;
    }
  }

  &.source {
    a.v-list-item--active {
      background: #082835;

      .v-icon {
        color: #f6a962 !important;
      }

      .marketplace-icon path {
        fill: #f6a962 !important;
      }

      .v-list-item__title {
        font-weight: bold;
      }
    }

    .location-error-indication {
      color: #f6a962 !important;
    }
  }
}

nav {
  .toolbar-account {
    .avator {
      align-items: center;
      align-self: center;
      background-color: #80d8ff;
      border-radius: 50px;
      color: #fff;
      display: inline-flex;
      font-size: 13px;
      height: 32px;

      &.source {
        background-color: #f6a962;
      }

      &.destination {
        background-color: #ff6b7a;
      }
    }
  }

  .sublink-item {
    border-left: 5px solid orange;
  }

  .v-list-item--link:before {
    background-color: unset;
  }

  .v-list-item__title {
    font-size: 14px;
  }

  .store-name {
    display: block;

    i {
      color: inherit;
      transform: translateY(-1px);
    }
  }

  .addon-not-added {
    .v-list-item__icon i,
    .v-list-item__content {
      color: #d6d6d6 !important;
      opacity: 0.5;
    }

    .v-list-item__content {
      ~ .v-list-item__icon i {
        transform: translateY(1px);
      }
    }
  }

  .user-info {
    align-items: center;
    display: inline-flex;
    height: 42px;
    transition: 0.35s;

    &.active,
    &:hover {
      background: #f4f4f4;
    }
  }
}
</style>
