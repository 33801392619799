export const setProducts = {
  SET_PRODUCTS(state, payload) {
    payload.forEach(item => {
      if(item.product_commission_rate.value) {
        item.product_commission_rate.value = String(item.product_commission_rate.value)
      }
    })

    state.products = payload
    state.unMutatedProducts = JSON.parse(JSON.stringify(state.products))
  }
}
