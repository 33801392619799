import axios from "axios";

export const cancelPayout = {
  async cancelPayout({ commit }, payload)  {
    try {
      const response = await axios.post('stores/payout/cancel-payout', {
        ...payload
      });
    } catch (error) {
      console.log(error);
    }
  }
};
