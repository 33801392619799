import axios from "axios";

export const fetchByStore = {
  async fetchByStore ({ commit }, payload) {
    const response = await axios.get('stores/connections', {
      params: { ...payload }
    })

    if(response.data.success) {
      response.data.stores = response.data.stores.filter(store => store.platform === 'shopify')
      response.data.stores.forEach(store => { store.isSelected = false })
      commit('SET_CONNECTIONS', response.data.stores)
    }
  }
}
