import UserApiService from "@/common/api/user.service";
import SessionData from "@/common/sessionData.service"
import $ from "jquery";
import _ from 'lodash';
import {
    SAVE_PLAN
} from "@/store/actions.type";

const initialState = {
    user_id: null,
    currentActivePlan: null,
    selectedNewPlan: null,
    availablePlansList: [],
    storeId: null,
    storeName: null,
    syncioKey: null,
    storeType: null,
    monthlyOrderCount: null,
    isError: false,
    isOnboarding: false,
    isPlanNull: false,
};

export const state = { ...initialState };

const getters = {
    isError(state) {
        return state.isError;
    },
    isOnboarding(state) {
        return state.isOnboarding;
    },

    isOrderModuleAvailable(state) {
        if(state.currentActivePlan) {
            let ordersPlan = state.currentActivePlan.active_addons.filter(plan => plan.name === 'Orders')
            return ordersPlan.length === 1
        }
      },

    isProductModuleAvailable(state) {
        if(state.currentActivePlan) {
            let settingsPlan = state.currentActivePlan.active_addons.filter(plan => plan.name === 'Product Settings')
            return settingsPlan.length === 1
        }
    },

    isPayoutsModuleAvailable(state) {
        if(state.currentActivePlan) {
            let payoutsPlan = state.currentActivePlan.active_addons.filter(plan => plan.name === 'Payouts')
            return payoutsPlan.length === 1
        }
    }
};

const actions = {
    init({dispatch, state}, {userId}) {
        if (typeof(userId) === 'undefined' || userId === null) {
            state.user_id = SessionData.getUserID();
        }
        dispatch('loadAvailablePlans').then((response) => {
            if (response.success){
                dispatch('getCurrentPlan', true);
            }
        });
    },
    getCurrentPlan({state}, copyToNewPlan) {
        if (state.user_id === null) {
            state.user_id = SessionData.getUserID();
        }
        UserApiService.getCurrentPlans(state.user_id)
            .then((response) => {
                response = response.data;
                if (response.success) {
                    state.currentActivePlan = response.plan;
                    state.isPlanNull = response.plan;
                    if (state.currentActivePlan === null){
                        state.currentActivePlan = {name: state.availablePlansList[0].name};
                        state.currentActivePlan.syncio_plan = Object.assign({}, state.availablePlansList[0]);
                        //empty plan make onboarding true
                        state.isOnboarding = true;
                    }
                    if (copyToNewPlan) {
                        state.selectedNewPlan = Object.assign({}, state.currentActivePlan.syncio_plan);
                        state.selectedNewPlan.id = state.currentActivePlan.syncio_plan.id;
                        state.currentActivePlan.name = state.currentActivePlan.name.replace(' Plus', '');
                        if (!state.currentActivePlan.syncio_plan.is_active) {
                            state.currentActivePlan.name += ' (legacy)'
                        }
                        state.selectedNewPlan.name = state.currentActivePlan.syncio_plan.name.replace(' Plus', '');
                        state.selectedNewPlan.price_per_month = state.currentActivePlan.syncio_plan.price_per_month;
                        state.selectedNewPlan.active_addons = [];
                        if (typeof (state.currentActivePlan.active_addons) != 'undefined') {
                            $.each(state.currentActivePlan.active_addons, (key, addon) => {
                                addon.selected = true;
                                state.selectedNewPlan.active_addons.push(addon);
                            });
                        }
                        state.currentActivePlan.current_addons = _.cloneDeep( state.selectedNewPlan.active_addons);
                        // use this line here to convert the addons back to array in case it only have one item which show as an object.
                        state.currentActivePlan.active_addons = Object.assign([], state.selectedNewPlan.active_addons);
                    }
                } else {
                    alert("Error: Can't load the current plan data!")
                }
            })
            .catch((error) => {
                console.log(error);
            })
    },
    loadAvailablePlans({ state }) {
        return new Promise((resolve) => {
            UserApiService.getAllPlans(state.user_id)
                .then((response) => {
                    response = response.data;
                    if(response.success) {
                        state.availablePlansList = response.plans;
                        resolve(response)
                    } else {
                        alert('Plans loading error!')
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    },
    [SAVE_PLAN](context, params){
        return new Promise((resolve, reject) => {
            UserApiService.savePlan(params)
                .then(({ data }) => {
                    context.rootState.auth.showAddonsDialog = false;
                    resolve(data);
                })
                .catch((response) => {
                    if (!response.data.success){
                        state.isError = true;
                    }
                    reject(response)
                })
        });
    },
};

const mutations = {
    SET_SELECTED_NEW_PLAN_BY_ID (state, planId) {
        if (state.currentActivePlan != null) {
            if (state.currentActivePlan.id === planId) {
                return;
            }
        }
        let newPlan = null;
        $.each(state.availablePlansList, (key, plan) => {
            if (plan.id != planId) {
                return;
            }
            newPlan = Object.assign({},plan);
            if (state.selectedNewPlan != null) {
                newPlan.active_addons = state.selectedNewPlan.active_addons;
            } else if (state.currentActivePlan != null) {
                newPlan.active_addons = Object.assign({}, state.currentActivePlan.active_addons);
            }

            // Hack to show price update on frontend.
            newPlan.active_addons.forEach(addon => {
                if(addon.module_id === 1) {
                    addon.price_per_month = 22;
                } else if(addon.module_id === 2) {
                    addon.price_per_month = 19;
                }
            })
        });

        // If we can't find any active plan for this plan ID then we need to check is it related to current plan
        // if so we set the current plan to newPlan
        if (newPlan === null && planId === state.currentActivePlan.syncio_plan.plan_id) {
            newPlan = Object.assign({}, {
                "id": state.currentActivePlan.syncio_plan.plan_id,
                "active": state.currentActivePlan.syncio_plan.active,
                "description": state.currentActivePlan.syncio_plan.description,
                "limit": state.currentActivePlan.syncio_plan.limit,
                "price_per_month": state.currentActivePlan.syncio_plan.price_per_month,
                "name": state.currentActivePlan.syncio_plan.name,
            });
        }

        state.selectedNewPlan = Object.assign({}, newPlan);
    },
    SET_SELECTED_NEW_PLAN (state, plan) {
        state.selectedNewPlan = null;
        state.selectedNewPlan = plan;
    },
};

const namespaced = true

export default {
    namespaced,
    state,
    actions,
    mutations,
    getters
};
