import ApiService from "@/common/api/base.service";

/**
 * Stores/ Shops related API to the backend
 */
export default {
  createStore(params) {
    return ApiService.post("stores/create", params);
  },
  getAccountStores(pageNumber, searchStr) {
    return ApiService.get(
      "stores",
      `?page=${pageNumber}&searchStr=${searchStr}`
    );
  },
  getShop(id) {
    return ApiService.get(`stores/${id}`);
  },
  getShopConnections(id, searchStr) {
    return ApiService.get(`stores/connections?current_store_id=${id}&search_str=${searchStr}`);
  },
  getShopConnectionsByUrl(url) {
    return ApiService.get(url);
  },
  connectShop(currentShopId, targetShopIdentifier) {
    let params = {
      current_store_id: currentShopId,
      target_store_identifier: targetShopIdentifier,
    };
    return ApiService.post(`stores/connect`, params)
  },
  disconnectShop(connectionId, willKeepProduct) {
    let params = {
      connection_id: connectionId,
      keeping_products: willKeepProduct
    };

    return ApiService.post("stores/disconnect", params);
  },
  inviteShop(toEmail, shopId) {
    let params = {
      to_email: toEmail,
      store_id: shopId
    };
    return ApiService.post("stores/invite-to-connect", params);
  },
  setShopType(shopId, shopType) {
    let params = {
      store_id: shopId,
      type: shopType,
    }
    return ApiService.post("stores/install/set-store-type", params)
  },
  uninstall(storeId) {
    let params = {
      store_id: storeId
    };
    return ApiService.post("uninstall", params);
  },
  fetchSourceStoreInventoryAssignmentsForDestinations(sourceStoreId) {
    return ApiService.get(`stores/${sourceStoreId}/inventory-assignments`);
  },
  setInventoryLocationToShop(params) {
    return ApiService.post("stores/update-inventory-location", params);
  },
  updateShopInfo(shopId, params) {
    return ApiService.post(`stores/${shopId}`, params);
  },
};