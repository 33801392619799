import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "LoginPage",
    component: () => import("@/views/LoginPage")
  },
  {
    path: "/logout",
    name: "LogoutPage",
    component: () => import("@/views/LogoutPage")
  },
  {
    path: '/forgot-password',
    name: 'ForgotPasswordPage',
    component: () => import('@/views/registration/pages/woocommerce/ForgotPassword')
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordPage',
    component: () => import('@/views/registration/pages/woocommerce/ResetPassword')
  },
  {
    path: "/registration",
    name: "",
    component: () => import("@/views/registration/layout/RegistrationBase"),
    meta: {
      layout: "registration-base"
    },
    children: [
      {
        path: "",
        name: "registration",
        component: () => import("@/views/registration/pages/SignUpPage")
      },
      // WooCommerce Installation Workflow
      {
        path: "woocommerce/create-account",
        name: "registration.woocommerce.create-account",
        component: () =>
          import("@/views/registration/pages/woocommerce/CreateAccount")
      },
      {
        path: "woocommerce/select-store-type",
        name: "registration.woocommerce.select-store-type",
        component: () =>
          import("@/views/registration/pages/woocommerce/SelectStoreType"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "woocommerce/verify-store-url",
        name: "registration.woocommerce.verify-store-url",
        component: () =>
          import("@/views/registration/pages/woocommerce/VerifyStoreURL"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "woocommerce/permissions",
        name: "registration.woocommerce.permissions",
        component: () =>
          import("@/views/registration/pages/woocommerce/Permissions"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "woocommerce/select-plan",
        name: "registration.woocommerce.select-plan",
        component: () => import("@/views/plan/pages/PlanSelectionPage"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "woocommerce/continue-later",
        name: "registration.woocommerce.continue.later",
        component: () => import("@/views/registration/pages/ContinueLater"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/shopify",
    name: "",
    component: () => import("@/views/registration/layout/EmptyBase.vue"),
    children: [
      {
        path: "",
        name: "shopify",
        component: () =>
          import("@/views/registration/pages/shopify/ShopifyEntry.vue")
      },
      {
        path: "/shopify/select-store-type",
        name: "shopify.select-store-type",
        component: () =>
          import("@/views/registration/pages/shopify/SelectStoreType")
      },
      {
        path: "/shopify/connect-and-invite-store-via-source-store", // new
        name: "shopify.connect-and-invite-store-via-source-store",
        component: () =>
          import(
            "@/views/registration/pages/shopify/ConnectAndInviteStoreViaSourceStore"
          )
      },
      {
        path: "/shopify/connect-and-invite-store",
        name: "shopify.connect-and-invite-store",
        component: () =>
          import("@/views/registration/pages/shopify/ConnectAndInviteStore")
      },
      {
        path: "/shopify/connect-and-invite-store/connect-store",
        name: "shopify.connect-and-invite-store.connect-store",
        component: () =>
          import("@/views/registration/pages/shopify/ConnectStore")
      },
      {
        path: "/shopify/connect-and-invite-store/connect-destination-store",
        name: "shopify.connect-and-invite-store.connect-destination-store",
        component: () =>
          import("@/views/registration/pages/shopify/ConnectDestinationStore")
      },
      {
        path: "/shopify/connect-and-invite-store/invite-store",
        name: "shopify.connect-and-invite-store.invite-store",
        component: () =>
          import("@/views/registration/pages/shopify/InviteStore")
      },
      {
        path: "/shopify/connect-and-invite-store/invite-destination-store",
        name: "shopify.connect-and-invite-store.invite-destination-store",
        component: () =>
          import("@/views/registration/pages/shopify/InviteDestinationStore")
      },
      {
        path: "/shopify/select-plan",
        name: "shopify.select-plan",
        component: () => import("@/views/plan/pages/PlanSelectionPage")
      },
      {
        path: "/shopify/installation-complete",
        name: "shopify.installation-complete",
        component: () =>
          import("@/views/registration/pages/shopify/InstallationComplete")
      },
      {
        path: "/shopify/source-store-installation-complete",
        name: "shopify.source-store-installation-complete",
        component: () =>
          import(
            "@/views/registration/pages/shopify/SourceStoreInstallationComplete"
          )
      }
    ]
  },
  {
    path: "/woocommerce",
    name: "",
    component: () => import("@/views/registration/layout/EmptyBase.vue"),
    children: [
      {
        path: "",
        name: "woo.jwt-login",
        component: () =>import("@/views/registration/pages/woocommerce/WooEntry")
      },
      {
        path: "installation-completed",
        name: "woo.installation-complete",
        component: () => import("@/views/registration/pages/woocommerce/InstallationComplete"),
      }
    ]
  },
  {
    path: "/shops",
    name: "ShopManagementPage",
    component: () => import("@/views/shop/pages/ShopManagementPage"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/dashboard",
    name: "DashboardPage",
    component: () => import("@/views/dashboard/DashboardView.vue"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    component: () => import("@/views/marketplace/Marketplace.vue"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/products",
    name: "ProductManagementPage",
    component: () => import("@/views/product/pages/ProductManagementPage"),
    props: true,
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/settings/products/sync-configs",
    name: "ProductConfigurationsPage",
    component: () => import("@/views/product/pages/ProductConfigurationsPage"),
    props: true,
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/orders",
    name: "OrderManagementPage",
    component: () => import("@/views/order/pages/OrderManagementPage"),
    props: true,
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    },
    children: [
      // {
      //   path: "/push-configs",
      //   name: "PushOrderSettingsPage",
      //   component: () => import('@/views/order/pages/PushOrderSettingsPage'),
      // },
    ]
  },
  {
    path: "/orders/push-configs",
    name: "PushOrderSettingsPage",
    component: () => import("@/views/order/pages/PushOrderSettingsPage"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/activity-logs",
    name: "ActivityLogsPage",
    component: () =>
      import("@/views/activity-logs/pages/ActivityLogListingPage"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/payouts",
    name: "Payouts",
    component: () => import("@/views/payouts/pages/destination/Payable"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/payouts/unpaid",
    name: "UnpaidPayouts",
    component: () => import("@/views/payouts/pages/destination/Unpaid"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/payouts/paid",
    name: "PaidPayouts",
    component: () => import("@/views/payouts/pages/destination/Paid"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/source-payouts/",
    name: "SourcePayouts",
    component: () => import("@/views/payouts/pages/source/Open"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/source-payouts/complete",
    name: "CompleteSourcePayouts",
    component: () => import("@/views/payouts/pages/source/Complete"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: '/activity-center',
    name: 'ActivityCenter',
    component: () => import('@/views/activity-center/ActivityCenter'),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout",
    },
  },
  // Hybrid Routes
  {
    path: "/hybrid/shopify/v1/order-module-access",
    name: "HybridOrderModuleAccess",
    component: () => import("@/views/order/pages/HybridOrderModuleAccess"),
    props: true,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/settings/account/general",
    name: "AccountSettingsPage",
    component: () => import("@/views/general/pages/AccountSettingsPage"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/account/billing",
    name: "PlanSelectionPage",
    component: () => import("@/views/plan/pages/PlanSelectionPage"),
    props: true,
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/shopify/plan/confirmation",
    name: "ShopifyPlanSelectionPage",
    component: () => import("@/views/plan/pages/PlanSelectionPage"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/session-expired",
    props: true,
    component: () => import("@/views/SessionExpired.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/settings/Settings.vue"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/settings/notification-settings",
    name: "NotificationSettings",
    component: () => import("@/views/settings/Notifications.vue"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/settings/payout-settings",
    name: "PayoutSettings",
    component: () => import("@/views/settings/payout/PayoutSettings.vue"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "/settings/marketplace-settings",
    name: "MarketplaceSettings",
    component: () => import("@/views/settings/marketplace/MarketplaceSettings.vue"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout"
    }
  },
  {
    path: "*",
    component: () => import("@/views/NotFound.vue"),
    meta: {
      requiresAuth: false
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

//Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.auth.isAuthenticated;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        name: "LoginPage",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
