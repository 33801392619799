export const setConnections = {
  SET_CONNECTIONS(state, payload) {
    payload.forEach(item => {
      if(item.store_commission_rate.value) {
        item.store_commission_rate.value = String(item.store_commission_rate.value)
      }
    })
    state.connections = payload
    state.unMutatedConnections = JSON.parse(JSON.stringify(state.connections))
  }
}
