import ApiService from "@/common/api/base.service";

export const fetchCustomShippingTags = {
  async fetchCustomShippingTags({ commit }, { store_id }) {
    try {
      const response = await ApiService.get(`stores/${store_id}/orders/custom-shipping-tags`);
      commit('SET_CUSTOM_SHIPPING_TAGS', response.data);
    } catch(error) {
      console.log(error);
    }
  }
};
