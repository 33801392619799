import ApiService from "@/common/api/base.service";

export const updateMultiLocationInventory = {
  async updateMultiLocationInventory({ commit, rootState }, { store_id, value }) {
    let currentState = !rootState.shop_2_0.isMultiLocationEnabled;
    try {
      const response = await ApiService.post(`stores/${store_id}/multi-locations/toggle`, { value });
    } catch(error) {
      rootState.shop_2_0.isMultiLocationEnabled = currentState;
    }
  }
};
