import ApiService from "@/common/api/base.service";

export const fetchDestinationStoreLocations = {
  async fetchDestinationStoreLocations({ commit }, { current_store_id }) {
    try {
      const response = await ApiService.get(`stores/${current_store_id}/destination-locations`);
      commit('SET_DESTINATION_STORE_LOCATIONS', response.data.inventories);
    } catch(error) {
      console.log(error);
    }
  }
};
