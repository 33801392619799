<script>
import { createHelpers } from "vuex-map-fields";
import InformationDialog from './InformationDialog.vue';

const { mapFields } = createHelpers({
  getterType: 'auth/getField',
  mutationType: 'auth/updateField',
});

export default {
  name: 'UpdateAddonStepOne',

  computed: {
    ...mapFields([
      "showAddonsDialog",
      "showUpdateAddonsDialogOne",
      "showUpdateAddonsDialogThree",
      "showUpdateAddonsDialogTwo",
    ]),
  },

  components: {
    InformationDialog
  },

  methods: {
    updateHandler() {
      this.$router.push({
        name: 'PlanSelectionPage'
      }).then(() => {
        this.showUpdateAddonsDialogOne = false
        this.showUpdateAddonsDialogTwo = true
      }).catch(() => {
        this.showUpdateAddonsDialogOne = false
        this.showUpdateAddonsDialogTwo = true
      });
    },

    closeUpgradeDailog() {
      this.showUpdateAddonsDialogOne = false
      this.showUpdateAddonsDialogThree = true
    }
  }
}
</script>

<template>
  <InformationDialog width="525px" title="Update your Add-on Plan" :showDialog="showUpdateAddonsDialogOne && showAddonsDialog">
    <template #closeDialog>
      <v-icon size="30px" color="#ffffff" class="mt-1 pointer" @click="closeUpgradeDailog">close</v-icon>
    </template>
    <p class="mb-5">Legacy add-on pricing has changed.</p>
    <p class="mb-5">To ensure your add-ons continue to receive the <br /> latest features and support, please click "Update".</p>
    <p>How will the update affect me? <br /> Find out more <a href="https://www.notion.so/syncio/Add-on-Plan-Price-Change-fcd8e855d18149629eddb246deebda8d" target="_blank" class="link" style="text-decoration: none;">here</a>.</p>
    <v-btn height="48px" class="btn-primary mt-3 px-5" elevation="0" width="200px" @click="updateHandler">Update</v-btn>
  </InformationDialog>
</template>
