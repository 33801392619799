import ApiService from "@/common/api/base.service";

export const bulkPush = {
  async bulkPush({ commit }, { store_Id, shipping_cost, order_ref_ids }) {
    try {
      const response = await ApiService.post(`stores/${store_Id}/orders/bulk-order-push`, {
        shipping_cost,
        order_ref_ids
      })
    } catch (error) {
      console.log(error);
    }
  }
}
