import Vue from 'vue'
import Vuex from 'vuex'
import activityCenter from '@/store/modules/activity-center'
import activityLogs from '@/store/modules/activity-logs'
import auth from '@/store/modules/auth'
import configs from '@/store/modules/configs'
import marketplace from '@/store/modules/marketplace'
import notification from '@/store/modules/notification'
import orders from '@/store/modules/orders'
import orders_2_0 from '@/store/modules/orders_2_0'
import payouts from '@/store/modules/payouts'
import payoutSettings from '@/store/modules/payout-settings'
import plan from '@/store/modules/plan'
import processQueue from '@/store/modules/process-queue'
import product from '@/store/modules/product'
import shop from '@/store/modules/shop'
import shop_2_0 from '@/store/modules/shop_2_0'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    activityCenter,
    activityLogs,
    auth,
    configs,
    marketplace,
    notification,
    orders_2_0,
    orders,
    payouts,
    payoutSettings,
    plan,
    processQueue,
    product,
    shop_2_0,
    shop,
  },
})
