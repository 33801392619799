import axios from "axios";

export const addStoreCommission = {
  async addStoreCommission({ commit }, payload) {
    const { current_store_id, storeCommissions } = payload
    const response = await axios.post(`stores/${current_store_id}/bulk-commission-rates-update`, {
      destination_store_id: current_store_id,
      commission_rates: storeCommissions
    })
  }
}
