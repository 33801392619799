import {
    ADD_NOTIFICATION, DESTROY_NOTIFICATION, CLEAN_LIST, DESTROY_NOTIFICATION_BY_ID
} from "@/store/actions.type";

const STATE = {
  IDLE: 0,
  DESTROYED: 1,
}

const mutations = {
    // [SET_NOTIFICATIONS_LIST](state, list) {
    //     state.notificationsList = list;
    // }
};

const actions = {
    [ADD_NOTIFICATION]({ state, dispatch }, { notification }) {
        let duplicated = state.notificationsList.some(item => {
            return (
                item.body === notification.body &&
                item.type === notification.type &&
                item.position === notification.position
            );
        });
        // we are going to ignore the duplicate
        if (duplicated) {
            return;
        }
        
        notification.timer = setTimeout(() => {
                dispatch(DESTROY_NOTIFICATION_BY_ID, { id: notification.id })
            }, notification.length);
        state.notificationsList.push(notification);
        // we only hold max 5 notifications
        if (state.notificationsList.length > 5) {
            dispatch(DESTROY_NOTIFICATION, {
                notification: state.notificationList[0],
            })
        }

    },
    [CLEAN_LIST]({ state }) {
        state.notificationsList = state.notificationsList.filter((notification) => {
            return notification.state != STATE.DESTROYED;
        });
    },
    [DESTROY_NOTIFICATION]({ dispatch }, { notification }) {
        clearTimeout(notification.timer);
        notification.state = STATE.DESTROYED;
        dispatch(CLEAN_LIST);
    },
    [DESTROY_NOTIFICATION_BY_ID]({ state, dispatch }, { id }) {
        let item = state.notificationsList.find(v => v.id === id);
        if (item) {
            dispatch(DESTROY_NOTIFICATION, { notification: item });
        }
    }
};

export default {
    namespaced: true,
    state: {
        notificationsList: [],
    },
    mutations,
    actions,
}
