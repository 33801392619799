import deepmerge from "deepmerge";

/* Actions */
import { fetchDestinationStoreLocations } from "./actions/fetchDestinationStoreLocations";
import { updateDestinationStoreLocation } from "./actions/updateDestinationStoreLocation";
import { updateMultiLocationInventory } from "./actions/updateMultiLocationInventory";
import { updateStoreCommission } from "./actions/updateStoreCommission";

/* Mutations */
import { setDestinationStoreLocations } from "./mutations/setDestinationStoreLocations";
import { updateField } from 'vuex-map-fields';

const state = {
  destinationStoreLocations: [],
  isMultiLocationEnabled: false,

  // Commission
  selectedStore: {},
  isCommissionModalVisible: false,
  isCommissionFormValid: true,
};

const actions = deepmerge.all([
  fetchDestinationStoreLocations,
  updateDestinationStoreLocation,
  updateMultiLocationInventory,
  updateStoreCommission
]);

const mutations = deepmerge.all([
  setDestinationStoreLocations,
  {}
]);

import { getters } from "./getters";

mutations['updateField'] = updateField

export default {
  actions,
  getters,
  mutations,
  state,
  namespaced: true
};
