<template>
  <nav v-if="currentShop != null" >
      <v-app-bar
          app
          flat
          color="white"
      >
        <v-toolbar-title
        >
          <v-img
              v-on:click="TakeMeBack"
              height="54"
              src="@/assets/images/logo.svg"
              max-width="40px"
          ></v-img>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div>
          Store: <span>{{ getStoreName(this.currentShop.store_domain) }}</span>
          <v-icon class="pa-2" color="#007ace" v-on:click="TakeMeToSettings">settings</v-icon>
          <v-icon class="pa-2" color="#007ace" v-on:click="TakeMeToActivityLogs">assignment</v-icon>
          <v-icon class="pa-2" color="#007ace" v-on:click="TakeMeBack">home</v-icon>
          <v-icon class="pa-2 " color="#b8bdc3" v-on:click="LogMeOut">meeting_room</v-icon>
        </div>
      </v-app-bar>
  </nav>
</template>

<script>
import {mapGetters} from 'vuex'
import { VUE_APP_V1_BASE_URL } from "@/config";

export default {
  name: "HybridNavbar",
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters('shop', ['currentShop']),
  },
  methods:{
    getStoreName(storeDomain){
      let storeShortName = storeDomain.split(".myshopify.com")
      return storeShortName[0];
  },
    TakeMeBack(){
      window.location.href=VUE_APP_V1_BASE_URL+"/retailers";
    },
    TakeMeToSettings(){
      window.location.href=VUE_APP_V1_BASE_URL+"/retailers/settings";
    },
    TakeMeToActivityLogs(){
      window.location.href=VUE_APP_V1_BASE_URL+"/stores/activity-logs";
    },
    LogMeOut(){
      this.$router.push("logout");
    }
  }
}
</script>

<style lang="scss" scoped>
nav {
  header{
    ::v-deep .v-toolbar__content{
      max-width: 1100px !important;
      margin: auto;
    }
  }
}

</style>