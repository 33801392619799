<template>
  <div>
    <notification-component
        position="top-left"
    ></notification-component>

    <notification-component
        position="top-right"
    ></notification-component>

    <notification-component
        position="bottom-right"
    ></notification-component>

    <notification-component
        position="bottom-left"
    ></notification-component>
  </div>
</template>

<script>
import NotificationComponent from "@/views/components/NotificationComponent";
export default {
  name: "AllNotificationComponent",
  components: {NotificationComponent}
}
</script>

<style scoped>

</style>