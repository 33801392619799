<template>
  <v-app class="grey lighten-4">
    <HybridNavbar/>
    <v-container class="mx4 mb-4">
      <router-view></router-view>
    </v-container>
    <processing-station></processing-station>
    <all-notification-component></all-notification-component>
  </v-app>
</template>

<script>
import HybridNavbar from "@/views/components/HybridNavbar";
import ProcessingStation from "@/views/components/ProcessingStation";
import AllNotificationComponent from "@/views/components/AllNotificationComponent";

export default {
  name: "HybridLayout",
  components: {
    AllNotificationComponent,
    HybridNavbar,
    ProcessingStation,
  },
}
</script>

<style scoped>

</style>