import Vue from "vue";
import axios from "axios";
import router from "@/router";
import store from '@/store/index';
import VueAxios from "vue-axios";
import {API_URL, VUE_APP_ID} from "@/config";

import JwtService from "@/common/jwt.service";
import {ADD_NOTIFICATION} from "@/store/actions.type";

export default {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.defaults.headers.common["x-syncio-app-id"] = VUE_APP_ID;

    Vue.axios.interceptors.response.use(
        (response) => {
        if (response.status === 200 || response.status === 201) {
          if (typeof (response.headers['x-syncio-app-token']) != 'undefined') {
            JwtService.saveToken(response.headers['x-syncio-app-token']);
          }
          if (typeof(response.data.message) != 'undefined' && response.data.message != "" && response.data.message != null) {
            store.dispatch(`notification/${ADD_NOTIFICATION}`, {
              notification: {
                id: 'N' + (Math.floor(Math.random() * 100000000)),
                position: "bottom-left",
                type: "success",
                body: response.data.message,
                state: 0,
                length: 6000, // seconds
                data: null,
              },
            });
          }
            return Promise.resolve(response);
          } else {
            return Promise.reject(response);
          }
        },
        (error) => {
          if (error.response.status) {
            switch (error.response.status) {
              case 400:
                if (error.response.data.success === false){
                  let errors = error.response.data.errors;
                  errors.forEach(function (error) {
                    store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                      notification: {
                        id: 'N' + (Math.floor(Math.random() * 100000000)),
                        position: "bottom-left",
                        type: "error",
                        body: error,
                        state: 0,
                        length: 6000, // seconds
                        data: null,
                      },
                    });
                  })
                }
                if (typeof(error.response.data.redirect_to) != 'undefined') {
                  if (error.response.data.redirect_to === 'billing') {
                    if (router.currentRoute.name != "PlanSelectionPage") {
                      router.replace({ name: "PlanSelectionPage" });
                    }
                  }
                }
                break;
              case 422:
                if (error.response.data.success === false){
                  let errors = error.response.data.errors;
                  if (Array.isArray(errors)){
                    errors.forEach(function (error) {
                      store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                        notification: {
                          id: 'N' + (Math.floor(Math.random() * 100000000)),
                          position: "bottom-left",
                          type: "error",
                          body: error,
                          state: 0,
                          length: 6000, // seconds
                          data: null,
                        },
                      });
                    })
                  }
                }
                break;
              case 401:
              case 403:
                if(error.response?.config?.url.includes("get-banners") || (error.response?.config?.url.includes('/user/login'))) { return }
                router.replace({
                  path: "/session-expired",
                  query: {
                    redirect: router.currentRoute.fullPath,
                  },
                });
                break;
              case 500:
                store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                  notification: {
                    id: 'N' + (Math.floor(Math.random() * 100000000)),
                    position: "bottom-left",
                    type: "error",
                    body: "Unexpected error we are processing your requests.",
                    state: 0,
                    length: 6000, // seconds
                    data: null,
                  },
                });
                break;
              case 502:
                setTimeout(() => {
                  router.replace({
                    path: "/login",
                    query: {
                      redirect: router.currentRoute.fullPath,
                    },
                  });
                }, 1000);
            }
            return Promise.reject(error.response);
          }
        }
    );
  },
  setHeader() {
    const token = JwtService.getToken();
    if (token) {
      Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  },
  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  get(resource, slug = "") {
    const subpath = slug ? `/${slug}` : ``;
    return Vue.axios.get(`${resource}` + subpath).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};