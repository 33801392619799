export const setActivities = {
  SET_ACTIVITIES(state, { response, type }) {
    const { notifications, pagination } = response.data?.data
    switch (type) {
      case 'product':
        state.productIssues = notifications
        state.productIssuesPagination = pagination
        break;

      case 'order':
        state.orderIssues = notifications
        state.orderIssuesPagination = pagination
        break;

      case 'general':
        state.generalUpdates = notifications
        state.generalUpdatesPagination = pagination
        break;

      default:
        break;
    }
  }
}
