import axios from "axios";

export const createOrderMessages = {
  async createOrderMessages({ commit }, payload) {
    try {
      const response = await axios.post('/stores/create-order-messages', {
        ...payload
      })
    } catch (error) {
      console.log(error);
    }
  }
}
