import axios from "axios";

export const fetchPayoutPreview = {
  async fetchPayoutPreview({ commit }, { order_ids, target_store_id, current_store_id }) {
    try {
      const response = await axios.post("stores/payout/get-preview-payout", {
        order_ids,
        target_store_id,
        current_store_id
      });
      const flatResponse = response.data.data.flat();
      commit("SET_PAYOUTS_PREVIEW", flatResponse);
    } catch (error) {
      console.log(error);
    }
  }
};
