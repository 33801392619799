import ApiService from "@/common/api/base.service";

export default {
    loadConfigurations(userId) {
        return ApiService.get('/configurations/'+userId);
    },
    saveConfigurations(userId, data) {
        return ApiService.post('/configurations/' + userId, {
            configurations: data
        });
    }
};