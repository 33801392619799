<template>
  <div>
    <OnboardingHeader />
    <router-view></router-view>
  </div>
</template>

<script>
import OnboardingHeader from '../OnboardingHeader.vue'

export default {
  name: 'registrationBase',

  components: {
    OnboardingHeader
  }
}
</script>
