import ApiService from "@/common/api/base.service";

export const updateStoreCommission = {
  async updateStoreCommission({ commit }, payload) {
    try {
      const response = await ApiService.post(`stores/${payload.current_store_id}/add-store-commission`, {
        ...payload
      })
    } catch (error) {
      console.log(error);
    }
  }
}
