import axios from "axios";

export const fetchDashboardStats = {
  async fetchDashboardStats({ commit }, storeId) {
    try {
      const response = await axios.get(`stores/payout/dashboard-stats/${storeId}`);
      commit('SET_DASHBOARD_STATS', response.data.dashboardStats);
    } catch (error) {
      console.log(error);
    }
  }
};
