<template>
  <v-app :class="layout">
    <v-main style="transition: none;">
      <component :is="layout">
        <router-view></router-view>
      </component>
    </v-main>
  </v-app>
</template>

<script>
const default_layout = "default-layout";

export default {
  name: 'App',
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout);
    }
  },
};
</script>
<style lang="scss">
@import 'assets/fonts/Circular_Std_font/stylesheet.css';
</style>
