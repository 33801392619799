<script>
import { createHelpers } from "vuex-map-fields";
import axios from "axios";
import InformationDialog from './InformationDialog.vue';

const { mapFields } = createHelpers({
  getterType: 'auth/getField',
  mutationType: 'auth/updateField',
});

export default {
  name: 'UpdateAddonStepThree',

  data() {
    return {
      loading: false,
    }
  },

  components: {
    InformationDialog
  },

  computed: {
    ...mapFields([
      "showAddonsDialog",
      "showUpdateAddonsDialogThree",
      "showUpdateAddonsDialogTwo",
      "user_id",
    ]),
  },

  methods: {
    async updateHandler() {
      try {
        this.loading = true;
        const response = await axios.post('user/update-popup', {
          reminding_days: 30,
          user_id: this.user_id
        })

        this.showAddonsDialog = response.data.popup
        this.showUpdateAddonsDialogThree = false;
        this.loading = false;
      } catch(error) {
        this.showUpdateAddonsDialogThree = false;
        this.loading = false;
      }
    },
  }
}
</script>

<template>
  <InformationDialog width="525px" title="Update your Add-on Plan Later" :showDialog="showUpdateAddonsDialogThree">
    <p class="mb-5">No problem! We will send a reminder in a month.</p>
    <p class="mb-5">
      <a href="https://www.notion.so/syncio/Add-on-Plan-Price-Change-fcd8e855d18149629eddb246deebda8d" target="_blank" class="link" style="text-decoration: none;">Read about the Add-on plan price change</a>
    </p>
    <v-btn height="48px" class="btn-primary mt-10 px-5" elevation="0" width="200px" @click="updateHandler" :loading="loading">Continue</v-btn>
  </InformationDialog>
</template>
