import axios from "axios";
import { filtersURL } from "@/utilities/filtersURL";

export const fetchPayableOrders = {
  async fetchPayableOrders({ commit }, { storeId, filters }) {
    let generatedFiltersURL = filtersURL.generate(filters);

    try {
      const response = await axios.get(`stores/payout/all-store-data/${storeId}?${generatedFiltersURL}`);
      commit('SET_PAYABLE_ORDERS', response.data.payableOrders || {});
    } catch(error) {
      console.log(error);
    }
  }
}
