import ApiService from "@/common/api/base.service";

export default {
    login(email, password) {
        return ApiService.post("/user/login", {email, password});
    },
    shopifyLogin(storeName, platformType) {
        return ApiService.post("/user/platforms/login", {store_name: storeName, platform: platformType});
    },
    shopifyRelogin(storeName, platformType) {
        return ApiService.post("/user/platforms/login", {store_name: storeName, platform: platformType, relogin: true});
    },
    saveShopifyToken(params) {
        return ApiService.post("/shopify/2020-01/save-token", params)
    },
    wooLogin(jwt, store) {
        return ApiService.post("/wc/2020-01/jwt-login", {jwt, store})
    },
    registerUser(name, email, password, password_confirmation) {
        return ApiService.post("/user/register", {name, email, password, password_confirmation});
    },
    getUser(userId) {
        return ApiService.get("user", userId);
    },
    accessHybridOrderModule(storeName, platformType, appId) {
        return ApiService.post("/user/v1/order-module/access", {
            store_name: storeName,
            platform: platformType,
            appId: appId
        });
    },
};