export const filtersURL = {
  generate(filters) {
    let filtersURL = '';
    if (filters != null && typeof (filters) === "object" && filters.length > 0) {
      filters.forEach((filter, index) => {
        if(Object.prototype.hasOwnProperty.call(filter, "status")) {
          if (filter.status === "") { return; }
        }
        let filterFormat = `filters[${Object.keys(filter)[0]}]=${Object.values(filter)[0]}`;
        index === 0 ? filtersURL += filterFormat : filtersURL += `&${filterFormat}`;
      });
    }

    return filtersURL;
  }
};
